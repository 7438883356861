/**
 * Created by kuyarawa on 11/07/18.
 */

import React, {Component} from 'react'
import _ from 'lodash'
import fb from '../../assets/images/landing_page/Facebook Logo.png'
import instagram from '../../assets/images/landing_page/Instagram Logo.png'
import linkedin from '../../assets/images/landing_page/Linkedin Logo.png'
import logoBig from '../../assets/images/landing_page/TADO Logo Full.png'
import logoSmall from '../../assets/images/landing_page/TADO Logomark.png'
import './NewLandingPage.less'
import TermCondition from './TermCondition'
import Policy from './Policy'
import ProductTab from './ProductTab'
import BrandTab from './BrandTab'
import CreatorTab from './CreatorTab'
import AboutTab from './AboutTab'
import ContactTab from './ContactTab'
import CONFIG  from '../../constants/config'
import Home from "./Home";
import MobileDetect from '../../../node_modules/mobile-detect/';
const Nav = [
    {
        name:'Home',
        className:'home',
        bgColor:'#2C0A54',
        lineColorHover:'#17032F',
        link:'home'
    },
    {
        name:'Products',
        className:'product',
        bgColor:'#FBCE32',
        lineColorHover:'#501C8C',
        link:'product'
    },
    {
        name:'Blog',
        className:'blog',
        bgColor:'#FF5252',
        lineColorHover:'#F8BBD0',
        link:'blog',
        href: 'https://blog.tadotv.com/'
    },
    {
        name:'About Us',
        className:'about',
        bgColor:'#1CE6B3',
        lineColorHover:'#FBCE32',
        link:'about'
    },
    // {
    //     name:'About Us',
    //     className:'about',
    //     bgColor:'#1CE6B3',
    //     lineColorHover:'#FBCE32',
    //     link:'about'
    // },
    // {
    //     name:'Contact Us',
    //     className:'contact',
    //     bgColor:'#2962FF',
    //     lineColorHover:'#F8BBD0',
    //     link:'contact'
    // },
]
const iframeSlug = [{
    slug: 'bilibili',
    url: 'https://pengrajinkode.com/test-bullet.html'
}]
const md = new MobileDetect(window.navigator.userAgent);
class LandingPage extends Component {
    constructor(props) {
        super(props);
        const { match: { params } } = props;
        this.state = {
            activeTab: 'home',
            slug:params.slug,
            showMenuMobile:false,
            showHeader:false,
            messageHeader:'',
            showMessageHeader:false
        }
        this.toggleMenuMobile = this.toggleMenuMobile.bind(this);
        this.clickMenu = this.clickMenu.bind(this);
    }

    componentDidMount() {
        let tab = '';
        switch(this.state.slug){
            case 'term-and-condition':
                tab = 'term-condition';
                break;
            case 'privacy-policy':
            case 'bilibili':
            case 'privacy':
                tab = 'privacy-policy';
                break;
            case 'product':
                tab = 'product';
                break;
            case 'brand':
                tab = 'brand';
                break;
            case 'creator':
                tab = 'creator';
                break;
            case 'about':
                tab = 'about';
                break;
            case 'contact':
                tab = 'contact';
                break;
            default:
                if(this.state.slug!==undefined){
                    window.location.href='/';
                }
                break;
        }
        if(tab===''){
            tab = 'home';
        }
        this.setState({
            activeTab:tab,
            showHeader:tab!=='home'
        })
    }

    getActiveClass (tab) {
        return this.state.activeTab === tab ? 'active-'+tab : ''
    }

    clickMenu(tab){
        if (tab !== 'blog') {
          this.setState({
            activeTab:tab,
            showMenuMobile:false,
            showHeader:tab!=='home'
          })
        }
    }

    getTab(tab){
        switch(tab){
            case 'term-condition':
                return <TermCondition/>
            case 'privacy-policy':
                return <Policy/>
            case 'home':
                return <Home
                    clickMenu={(tab)=>this.clickMenu(tab)}
                    nav = {Nav}
                    toggleMenuMobile = {()=>this.toggleMenuMobile()}
                />
            case 'product':
                return <ProductTab clickMenu={(tab)=>this.clickMenu(tab)}/>
            case 'brand':
                return <BrandTab/>
            case 'creator':
                return <CreatorTab/>
            case 'about':
                return <AboutTab/>
            case 'contact':
                return <ContactTab
                    showMessageHeader={(message, isError = false)=>this.showMessageHeader(message, isError)}
                />
            default:
                return <ProductTab/>
        }
    }

    toggleMenuMobile(){
        this.setState({
            showMenuMobile:!this.state.showMenuMobile
        })
    }

    showMessageHeader(message, isError = false){
        console.log(message, isError, '??')
        this.setState({
            messageHeader:message,
            showMessageHeader:true,
            isMessageError: isError,
        },()=>{
            setTimeout(()=>{
                this.setState({
                    messageHeader:'',
                    showMessageHeader:false,
                });
            },5000)
        })
    }

    render() {
        const isIframe = iframeSlug.find(i => i.slug === this.state.slug);

        return !!isIframe ? (
            <iframe 
                title="tadoTV" 
                src={isIframe.url} 
                width="100%" 
                height="100%" 
                frameBorder="0" 
                allowFullScreen 
                style={{position:'fixed', top:0, left:0, bottom:0, right:0, width:'100%', height:'100%', border:'none', margin:0, padding:0, overflow:'hidden', zIndex:999999}}
            ><p>No Support</p></iframe>
        ) : (
            <div id="com-dark-theme" className={`lp-tab-${this.state.activeTab}`}>
                <div className={`com-message-header ${this.state.isMessageError ? 'error' : ''}`} style={{display:this.state.showMessageHeader?'block':'none'}}>
                    {this.state.messageHeader}
                </div>
                <div className="com-header" >
                    <div className="com-nav-button">
                        <button onClick={this.toggleMenuMobile}>
                            <i className="fa fa-bars">
                            </i>
                        </button>

                    </div>
                    <div className="com-logo">
                        <img src={logoBig} alt="logo"/>
                    </div>
                    <div className="com-nav">
                        <span style={{display:this.state.showHeader?'block':'none'}}>
                           {_.map(Nav, (opt, i) => {
                               return  <a key={i} href={opt.href ? opt.href : '#nav'} target={opt.href ? '_blank': ''} className={`com-nav-item nav-${opt.className} ${this.getActiveClass(opt.link)}`}
                                          onClick={()=>this.clickMenu(opt.link)}>
                                   {opt.name}
                               </a>
                           })}
                        </span>
                        <a href={CONFIG.studioUrl} target="_blank" className="com-sign-in">Studio Sign In</a>
                        <a href="#nav" className="com-demo" onClick={()=>this.clickMenu('contact')}>Contact Us <i className="fa fa-arrow-circle-right"></i></a>
                    </div>
                </div>
                <div className="com-container">
                    {
                        this.getTab(this.state.activeTab)
                    }

                </div>
                <div className="com-social" style={
                    {
                        display:(md.mobile()!==null && this.state.activeTab==='home')?'none':'flex'
                    }
                }>
                    <a href="https://www.instagram.com/tado.tv/" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="instagram"/>
                    </a>
                    <a href="https://www.facebook.com/TADO-TV-2274641872822097" target="_blank" rel="noopener noreferrer">
                        <img src={fb} alt="facebook"/>
                    </a>
                    <a href="https://www.linkedin.com/company/tado-tv/" target="_blank" rel="noopener noreferrer">
                        <img src={linkedin} alt="linkedin"/>
                    </a>
                </div>
                <div className="com-copyright" style={
                    {
                        display:(md.mobile()!==null && this.state.activeTab==='home')?'none':'flex'
                    }
                }>
                    <p>PT. Karya Anak Digital.
                        All Rights Reserved.
                        <strong>©2019</strong></p>
                    <div className="logo-small">
                        <img src={logoSmall} alt="logo"/>
                    </div>
                </div>
                <div className="com-nav-mobile" style={{display:this.state.showMenuMobile?'block':'none'}}>
                    <button href="#close" onClick={this.toggleMenuMobile} className="com-nav-close">X</button>
                    <ul>
                        {_.map(Nav, (opt, i) => {
                            return  <li key={i} className={`nav-item ${this.state.activeTab===opt.link?'active':''}`} onClick={()=>this.clickMenu(opt.link)}>
                                <div className="nav-item-color" style={{background:opt.bgColor}}>&nbsp;</div>
                                <div className="nav-item-text">{opt.href ? <a href={opt.href} target="_blank">{opt.name}</a> : opt.name}</div>
                            </li>
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

export default LandingPage;
