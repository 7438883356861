/**
 * Created by kuyarawa on 21/07/18.
 */

import React from 'react'
import './TermPolicy.less'
import twitter from '../../assets/icon/twitter@2x.png'
import fb from '../../assets/icon/facebook@2x.png'
import instagram from '../../assets/icon/instagram@2x.png'
import Lang from '../../utils/Lang'
class Policy extends React.Component {

    constructor(props) {
        super(props);
        this.lang = new Lang();
        const lang = this.lang.translate();
        this.state = {
            lang:lang,
            langId:'id',
        };
        this.changeLang = this.changeLang.bind(this);
    }

    componentDidMount() {

    }

    changeLang(langId){
        const lang = this.lang.translate(langId);
        this.setState({
            lang:lang,
            langId:langId
        });
    }

    getActiveClass(langId){
        return this.state.langId===langId?'active':''
    }

    render () {
        return (
            <div id="term-condition">
                <div className="header">
                    <h3>Privacy & Policy</h3>
                    <div className="change-lang">
                        <a href="#lang" className={this.getActiveClass('id')} onClick={()=>this.changeLang('id')}>Bahasa</a> | <a href="#lang" className={this.getActiveClass('en')} onClick={()=>this.changeLang('en')}>English</a>
                    </div>
                </div>
                <div className="content">
                    <h4>{this.state.lang.privacy_policy.title}</h4>
                    <ol>
                        <li>{this.state.lang.privacy_policy.content_1}</li>
                        <li>{this.state.lang.privacy_policy.content_2}</li>
                        <li>{this.state.lang.privacy_policy.content_3}</li>
                        <li>{this.state.lang.privacy_policy.content_4}</li>
                        <li>{this.state.lang.privacy_policy.content_5}</li>
                    </ol>
                    <h4>{this.state.lang.term_condition.contact_us}</h4>
                    <p>{this.state.lang.term_condition.contact_us_content}<a href="mailto:kami.tadotv@gmail.com">kami.tadotv@gmail.com</a></p>
                </div>
                <footer>
                    <p>©PT Karya Anak Digital. 2018. Read our <a href="privacy-policy" target="blank" rel="noopener noreferrer" >Privacy Policy</a> and <a href="term-and-condition" target="blank" rel="noopener noreferrer">Terms & Conditions</a></p>
                    <div className="socmed-wrapper">
                        <a href="https://www.facebook.com/TADO-TV-2274641872822097" target="_blank" rel="noopener noreferrer"><img src={fb} alt="logo"/></a>
                        <a href="https://twitter.com/tado_tv" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="logo"/></a>
                        <a href="https://www.instagram.com/tado.tv/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="logo"/></a>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Policy;
