/**
 * Created by kuyarawa on 23/07/18.
 */

import CONFIG from '../constants/config'
import axios from 'axios'

const axiosClient = axios.create({
    baseURL: CONFIG.apiUrl,
    // headers: {
    //   'x-access-token': 'Bearer '+localStorage.getItem('userToken')
    // }
});

export const axiosWebsite = axios.create({
    baseURL: CONFIG.apiWebsiteUrl,
    // headers: {
    //   'x-access-token': 'Bearer '+localStorage.getItem('userToken')
    // }
});

export const axiosClientTadoPlayer = axios.create({
    baseURL: CONFIG.apiTadoUrl,
    // headers: {
    //     'x-access-token': localStorage.getItem('tp-token')
    // }
});

export default axiosClient;
