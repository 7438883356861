import React from 'react';
import loadingClip from '../assets/images/loading-clip-alt.gif'
import './loadingVideo.less'
const LoadingVideo = ({text=''}) => {
    return (
        <div className="loader-box" >
            <div className="loading-video" >
                <span className="loading-text">{text}</span><span><img src={loadingClip} /></span>
            </div>
        </div>
    )
}

export default LoadingVideo;

