/**
 * Created by rinaldiguarsa on 20/06/19.
 */

import React from 'react'
import _ from "lodash"
import MobileDetect from '../../../node_modules/mobile-detect/';
import validator from 'validator';
import './Contact.less'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import {axiosWebsite} from "../../utils/axiosClient"; // Import css

const $ = require('jquery');

class ContactTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            md:new MobileDetect(window.navigator.userAgent),
            isBusy:false,
            subject:'',
            fullname:'',
            email:'',
            phone:'',
            company_name:'',
            message:''
        };
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentDidMount() {
      $('body').addClass('contact-page');
    }

    componentWillUnmount() {
      $('body').removeClass('contact-page');
    }

    handlerSubmit(){
        const error = this.validateForm();
        if(error.length>0){
            this.props.showMessageHeader(`⛔️ Submission failed. ${error[0]}`, true);
            // this.showErrorMessage(error);
        }else{
            this.setState({isBusy: true}, () => {
                axiosWebsite['post']('/v1.4/contact/us', {
                    subject : this.state.subject,
                    fullname : this.state.fullname,
                    email : this.state.email,
                    phone : this.state.phone,
                    company : this.state.company_name,
                    message : this.state.message
                }, {})
                    .then(response => {
                        this.setState({
                            subject: '',
                            email: '',
                            fullname: '',
                            company_name: '',
                            phone: '',
                            message: '',
                            isBusy: false,
                        },()=>this.props.showMessageHeader('🚀 Your message has been successfully submitted! We’ll be in touch with you soon'));
                    })
                    .catch(error => {
                        this.setState({
                            isBusy: false
                        },()=>{
                            this.props.showMessageHeader(`⛔️ Something Wrong. Please Try Again`, true);
                            // this.showErrorMessage(['Something Wrong. Please Try Again'])
                        });
                    });
            })
        }
    }

    showErrorMessage(error){
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='alert-error-contact'>
                        <h1>Error</h1>
                        <ul>
                            {_.map(error,(opt,i)=>{
                                return <li key={i} >{opt}</li>
                            })}
                        </ul>
                        <div className="button">
                            <button onClick={onClose}>Close</button>
                        </div>

                    </div>
                );
            }
        })
    }

    validateForm(){
        const {subject,fullname,email,phone,company_name,message} = this.state;
        let error = [];
        if(validator.isEmpty(subject)){
            error.push('Subject is not allowed to be empty');
        }
        if(validator.isEmpty(fullname)){
            error.push('Full Name is not allowed to be empty');
        }
        if(validator.isEmpty(email)){
            error.push('Email Address is not allowed to be empty');
        }else if(!validator.isEmail(email)){
            error.push('Email Address is not valid');
        }
        // if(validator.isEmpty(phone)){
        //     error.push('Phone is not allowed to be empty');
        // }else if(!validator.isNumeric(phone)){
        //     error.push('Phone format is invalid');
        // }
        // if(validator.isEmpty(company_name)){
        //     error.push('Company Name is not allowed to be empty');
        // }
        if(validator.isEmpty(message)){
            error.push('Message is not allowed to be empty');
        }
        return error;
    }

    render () {
        return (
            <div id="ct-contact">
                <div className="ct-left">
                    <div className="ct-left-content">
                        <h1 className="ct-com-title">Drop Us<br/> &lsquo;The Bomb&rsquo;</h1>
                        <p className="ct-com-name ct-com-caption">
                            PT. Karya Anak Digital <br />TADO Headquarter
                        </p>
                        <p className="ct-com-address ct-com-caption">
                            Jl. Permata Hijau No.27, RT.1/RW.12, Grogol Utara, Kec. Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12210
                        </p>
                        <a target="_blank" href="https://goo.gl/maps/Ch8XuCeoy3rV3bFc8"><button className="ct-com-maps" ><i className="fa fa-map-marker"></i>&nbsp;See on Google Map</button></a>
                    </div>
                </div>
                <div className="ct-right">
                    <div className="ct-right-content">
                        <form className="td-form" onSubmit={()=>this.handlerSubmit}>
                            <div className="td-form-group">
                                {!!this.state.subject && <label>Subject *</label>}
                                <input className="td-form-input"
                                       value={this.state.subject}
                                       onInput={(e)=>this.setState({subject:e.target.value})}
                                       disabled={this.state.isBusy}
                                       placeholder="Subject *"/>
                            </div>
                            <div className="td-form-group">
                                {!!this.state.fullname && <label>Your full name *</label>}
                                <input className="td-form-input"
                                       value={this.state.fullname}
                                       onInput={(e)=>this.setState({fullname:e.target.value})}
                                       placeholder="Your full name *"/>
                            </div>
                            <div className="td-form-group">
                                {!!this.state.email && <label>Your email address *</label>}
                                <input className="td-form-input"
                                       value={this.state.email}
                                       onInput={(e)=>this.setState({email:e.target.value})}
                                       disabled={this.state.isBusy}
                                       placeholder="Your email address *"/>
                            </div>
                            <div className="td-form-group">
                                {!!this.state.phone && <label>Your phone number</label>}
                                <input className="td-form-input"
                                       value={this.state.phone}
                                       onInput={(e)=>this.setState({phone:e.target.value})}
                                       disabled={this.state.isBusy}
                                       placeholder="Your phone number"/>
                            </div>
                            <div className="td-form-group">
                                {!!this.state.company_name && <label>Your company’s name</label>}
                                <input className="td-form-input"
                                       value={this.state.company_name}
                                       onInput={(e)=>this.setState({company_name:e.target.value})}
                                       disabled={this.state.isBusy}
                                       placeholder="Company’s name"/>
                            </div>
                            <div className="td-form-group">
                                <textarea className="td-form-input"
                                          value={this.state.message}
                                          onInput={(e)=>this.setState({message:e.target.value})}
                                          disabled={this.state.isBusy}
                                          placeholder="Type your message *">

                                </textarea>
                            </div>
                            <div className="required-field-info">* Required Field</div>
                            <div className="td-form-group">
                                <button type="button" className="td-form-submit" onClick={this.handlerSubmit}>Submit Now</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        )
    }
}

export default ContactTab;
