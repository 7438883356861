/**
 * Created by kuyarawa on 23/07/18.
 */
import CONFIG from '../constants/config'

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function generateRandomInt(digit) {
  return Math.floor(Math.random()*(9*(Math.pow(10, (digit - 1))))) + Math.pow(10, (digit - 1));
}

export function getS3ResizedImage(key, size) {
  return CONFIG.hlsS3Url + 'thumbnail/' + size + '/' + key
}

export function BrowserDetect() {
    let nav = window.navigator,
        ua = window.navigator.userAgent.toLowerCase();
// detect browsers (only the ones that have some kind of quirk we need to work around)
    if (ua.match(/ipad/i) !== null)
        return "iPod";
    if (ua.match(/iphone/i) !== null)
        return "iPhone";
    if (ua.match(/android/i) !== null)
        return "Android";
    if ((nav.appName.toLowerCase().indexOf("microsoft") !== -1 || nav.appName.toLowerCase().match(/trident/gi) !== null))
        return "IE";
    if (ua.match(/chrome/gi) !== null)
        return "Chrome";
    if (ua.match(/firefox/gi) !== null)
        return "Firefox";
    if (ua.match(/webkit/gi) !== null)
        return "Webkit";
    if (ua.match(/gecko/gi) !== null)
        return "Gecko";
    if (ua.match(/opera/gi) !== null)
        return "Opera";
//If any case miss we will return null
    return null
}

export function insideIframe(){
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}
export function mapDimensionQuality(index){
    switch (index){
        case 'low':
            return 240;
        case 'basic':
            return 360;
        case 'high':
            return 480;
        case 'ultra':
            return 720;
      default:
          return 240;
    }
}
export function mapIndexByQualityLabel(label){
    switch (label){
        case 'low':
            return 0;
        case 'basic':
            return 1;
        case 'high':
            return 2;
        case 'ultra':
            return 3;
        default:
            return -1;
    }
}
export function mapDimensionQualityByIndex(index){
    switch (index){
        case 0:
            return 240;
        case 1:
            return 360;
        case 2:
            return 480;
        case 3:
            return 720;
        default:
            return 240;
    }
}
export function mapTextQuality(index,langText){
    switch (index){
        case 'auto':
            return langText.automatic;
        case 'low':
            return langText.low;
        case 'basic':
            return langText.basic;
        case 'high':
            return langText.high;
        case 'ultra':
            return langText.ultra;
        default:
            return langText.automatic;
    }
}
export function mapTextQualityByIndex(index,langText){
    switch (index){
        case -1:
            return langText.automatic;
        case 0:
            return langText.low;
        case 1:
            return langText.basic;
        case 2:
            return langText.high;
        case 3:
            return langText.ultra;
        default:
            return langText.automatic;
    }
}
export function mapTextSubtitle(index,langText){
    switch (index){
        case "off":
            return langText.off;
        case "EN":
            return langText.english;
        case "ID":
            return langText.bahasa;
        default:
            return langText.off;
    }
}
export function vis(){
    let stateKey,
        eventKey,
        keys = {
            hidden: "visibilitychange",
            webkitHidden: "webkitvisibilitychange",
            mozHidden: "mozvisibilitychange",
            msHidden: "msvisibilitychange"
        };
    for (stateKey in keys) {
        if (stateKey in document) {
            eventKey = keys[stateKey];
            break;
        }
    }
    return function(c) {
        if (c) document.addEventListener(eventKey, c);
        return !document[stateKey];
    }
}

//Banner
export function createParamBanner(label, value){
    return value ? "&" + label + "=" + encodeURIComponent(value) : "";
}

export function cleanUrl(url){
    let keyword = url.replace(/[^A-Za-z0-9 ]/g,'');
    keyword = keyword.replace(/\s{2,}/g,' ');
    keyword = keyword.replace(/\s/g, "-");
    return keyword.toLowerCase();
}

export function createBannerUrl(adset = '',campaign = '',subdomain='',deeplink='',mediaSource = '',onelinkid=''){
    subdomain = subdomain!==''?subdomain:CONFIG.bannerSubDomain;
    onelinkid = onelinkid!==''?onelinkid:CONFIG.bannerOnelinkId;
    const bannerTag = "?af_banner=true";
    const baseUrl = "https://" + subdomain + ".onelink.me/" + onelinkid + bannerTag;

    // Attribution Settings
    mediaSource = mediaSource!==''?mediaSource:CONFIG.bannerMediaSource;
    mediaSource = createParamBanner("pid",mediaSource);
    campaign = campaign!==''?campaign:CONFIG.bannerCampaign;
    campaign = createParamBanner("c",campaign);
    adset = adset!==''?adset:CONFIG.bannerAdset;
    adset = createParamBanner("af_adset",cleanUrl(adset));

    // Deep link Settings
    deeplink = deeplink!==''?deeplink:CONFIG.bannerDeeplink;
    if(deeplink!==''){
        deeplink = createParamBanner("af_dp",deeplink)
    }
    // Build URL
    return baseUrl + mediaSource + campaign + adset + deeplink;
}

export function logger(key = '',message = '',type = 'log'){
    if(CONFIG.logDebug){
        switch (type){
            case 'log':
                console.log(key,message);
                break;
            case 'error':
                console.error(key,message);
                break;
            case 'info':
                console.info(key,message);
                break;
            case 'warn':
                console.warn(key,message);
                break;
            default:
                console.log(key,message);
                break;
        }
    }
}

export const convertToObject = (url) => {
    const arr = url.slice(1).split(/&|=/);
    let params = {};
    for(let i = 0; i < arr.length; i += 2){
        const key = arr[i], value = arr[i + 1];
        params[key] = value ;
    }
    return params;
};

export const getTimeUtc = ()=>{
    return Math.floor((new Date()).getTime() / 1000);
}

export const seekTime = 10; //in second
export const idleTimePause = 10; //in second
export const idleTimeVideoControl = 2; //in second
export const voidBridgeTime = 3; //in second

