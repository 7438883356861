/**
 * Created by rinaldiguarsa on 20/06/19.
 */

import React from 'react'
import MobileDetect from '../../../node_modules/mobile-detect/';
import './About.less';
import 'fullpage.js/dist/jquery.fullpage.min.css';

const $ = require('jquery');
$.fullpage = require('fullpage.js');

class AboutTab extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      md:new MobileDetect(window.navigator.userAgent)
    };
  }

  componentDidMount() {
    $(document).ready(function() {
      $('body').addClass('about-page');
      $('#fullpage').fullpage({
        navigation: true,
        navigationPosition: 'right',
        scrollHorizontally: true,
        loopHorizontal: false,
        controlArrows: false,
      });
    });
  }

  componentWillUnmount() {
    $.fn.fullpage.destroy('all');
    $('body').removeClass('about-page');
  }

  moveTo(index) {
    $.fn.fullpage.moveTo(index + 3);
  }

  render () {
    return (
      <div id="fullpage">
        <div className="section about about-1">
          <h2>Take Your Content to The Next Level</h2>
          <p>TADO delivers interactive video entertainment using choice based engine, where users can shape their own story </p>
        </div>
        <div className="section about about-2">
          <h2>Your Choice Matters</h2>
          <p>TADO is an interactive story telling platform that lets user be part of the story.</p>
          <p>We are building an ecosystem that bridges content creators, consumers, and brands through innovative technological platform.</p>
        </div>
        <div className="section journey about-3">
          <BreadCrumb activeIndex={0} moveTo={(i) => this.moveTo(i)} />
          <div className="right">
            <h2>Our Journey</h2>
            <div className="desc">
              <p>As a believer of human interactivity, we started the company  TADO Tanya Dong, making user generated Q&A video platform, connecting creators and influencers to users.</p>
              <p>Through that process, we discovered that video is the best medium to deliver message, but it lacks one thing — the freedom of choice.</p>
              <p>From that humble idea, we evolved into creating digital ads and branded contents through interactive creative storytelling</p>
            </div>
          </div>
        </div>
        <div className="section journey about-3">
          <BreadCrumb activeIndex={1} moveTo={(i) => this.moveTo(i)} />
          <div className="right">
            <h2>2019 Highlights</h2>
            <div className="desc">
              <div className="journey-card">
                <img src={require('../../assets/landing-page/card-1.png')} />
                <div className="card-info">
                  <span>May 2019</span>
                  <p>Serving First Client</p>
                </div>
              </div>
              <div className="journey-card">
                <img src={require('../../assets/landing-page/card-2.png')} />
                <div className="card-info">
                  <span>January 2019</span>
                  <p>TADO TV Released</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section journey about-3">
          <BreadCrumb activeIndex={2} moveTo={(i) => this.moveTo(i)} />
          <div className="right">
            <h2>2018 Highlights</h2>
            <div className="desc">
              <div className="journey-card">
                <img src={require('../../assets/landing-page/card-3.png')} />
                <div className="card-info">
                  <span>April 2018</span>
                  <p>Prototyping TADO TV</p>
                </div>
              </div>
              <div className="journey-card">
                <img src={require('../../assets/landing-page/card-4.png')} />
                <div className="card-info">
                  <span>February 2018</span>
                  <p>500 Registered Influencers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section journey about-3">
          <BreadCrumb activeIndex={3} moveTo={(i) => this.moveTo(i)} />
          <div className="right">
            <h2>2017 Highlights</h2>
            <div className="desc">
              <div className="journey-card">
                <img src={require('../../assets/landing-page/card-5.png')} />
                <div className="card-info">
                  <span>March 2017</span>
                  <p>TADO Q&A Released</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AboutTab;

const BreadCrumb = ({activeIndex, moveTo}) => {

  const navigation = [
    {title: 'Now'},
    {title: '2019'},
    {title: '2018'},
    {title: '2017'},
  ];
  return (
    <div className="left">
      <ul>
        {navigation.map((n, i) => {
          return (
            <li key={i} className={i === activeIndex ? 'active': ''} onClick={() => moveTo(i)}>
              <span>{n.title}</span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
