import React from 'react';

const InfoClip = ({genre,series_name,title,duration,synopsis}) => {
    return (
        <div className="info">
            <div className="genre">{genre}</div>
            <div className="genre" style={{display:(series_name!==""?'block':'none')}}>{series_name}</div>
            <div className="title">{title}</div>
            <div className="time"><i className="fa fa-clock"></i> {duration}</div>
            <p className="description">{synopsis}</p>
        </div>
    )
}

export default InfoClip;

