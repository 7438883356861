const en = {
  start:"Start",
  general:{
      seconds:'Detik',
      video_played:'Video Played',
      video_paused:'Video Paused',
      loading_text_start:'Nice idea, let\'s see where this will lead us to',
      loading_text_playing:'Cooking something up',
      rotate_info_title:'Rotate your phone for full screen',
      rotate_info_subtitle:'Please make sure your phone orientation is not locked',
  },
  share:{
      embed_content:"Embed Content",
      share_fb:"Share Facebook",
      share_content:"Share Content",
      share_twitter:"Share Twitter",
      copy_clipboard:"Link copied to the clipboard!",
      copy_tag:"Copy Tag",
      share_term:"By embedding TADOtv’s content on your site, you’re agreeing to ",
      share_term_2:"TADOtv’s API Terms of Service",
      copy:"Copy",
      copy_link:"Copy Link",
      or_share:"Or share to",
  },
  setting:{
    settings:"Settings",
    video_quality:"Video Quality",
    automatic:"Automatic",
    low:"Low",
    basic:"Basic",
    high:"High",
    ultra:"Ultra",
    off:"Off",
    bahasa:"Bahasa Indonesia",
    english:"English",
    sound_on:'Sound On',
    sound_off:'Sound Off',
  },
  end_poster:{
    last_checkPoint:"Last Checkpoint",
    replay_movie:"Replay Movie",
    next_episode:"Next Episode",
    enjoy_tado:"Enjoy other interactive video content by downloading the TADOtv application",
  },
  series:{
      episode_list:"Episode List",
      watching:"Watching"
  },
  bridge:{
    watch_tado:"Watch this option exclusively in the TADOtv application"
  },
  banner:{
    title:"TADOtv - Interactive Video Content",
    subtitle:"Watch interactive videos by downloading the TADOtv application",
    install:"Install",
  },
  term_condition:{
    title:'Terms & Conditions',
    sec_1_title:'TERMS AND CONDITIONS OF USE',
    sec_1_content:'This Terms and Conditions Of Use ("Term of Use") is made to govern the relations between PT Karya Anak Digital ("TADOTV," "we", "our", or "us") and its users ("users", "user", or "you") which is born by accessing or using our websites, our services, or any applications (including mobile applications) made available by TADOTV, TADOTV itself is an interactive vertical video platform where users could watch and decide the storyline of a selected content  (together, the "Service"). If you are under the age of majority in your country and are not an emancipated minor (in countries that permit this), you must have your parent\'s or guardian\'s consent to enter into this Terms of Use and to use the Service, and by using of the Service you acknowledge that you have received such consent.',
    sec_2_title:'AGREEMENT TO TERMS OF USE',
    sec_2_content_1:'By accessing or using the Service (whether or not you are a registered member), you are entering into a binding legal agreement with  PT Karya Anak Digital having its registered address at Podomoro City Ruko Garden Shopping Arcade Blok B/8 DH, Jl. S. Parman, Tanjung Duren Selatan, Grogol Petamburan, Jakarta Barat, Indonesia.',
    sec_2_content_2:'By accessing, using, registering for or receiving any of the Service, however accessed, you are agreed to be bound by the terms and conditions of this Terms of Use and to TADOTV Privacy Policy, which are incorporated herein by reference. This Terms of Use affect your legal rights and obligations.',
    sec_2_content_3:'You agree to only access or use the Service in compliance with the Terms of Use. If you do not accept and agree to the Terms of Use then you must not access or use the Service.',
    sec_3_title:'SEPARATE TERMS OF USE AND MODIFICATION',
    sec_3_content_1:'There may be times when we offered a special feature that has its own terms and conditions that apply in addition to this Terms of Use ("Separate Terms of Use"). In those cases, the terms specific to the special feature control to the extent there is a conflict with this Terms of Use.',
    sec_3_content_2:'TADOTV may modify the Terms of Use and Separate Terms of Use as deemed necessary, without providing prior notice to users. The modification will become effective once the modified Terms of Use or Separate Terms of Use are posted on an appropriate location within the website or application operated by TADOTV. Users shall be deemed to have granted valid and irrevocable consent to the modified Terms of Use or Separate Terms of Use by continuing to use the Service. Users shall refer to the Terms of Use on a regular basis when using the Service, since a separate notification regarding the modification to Terms of Use may not be provided.',
    sec_4_title:'TADOTV ACCOUNTS',
    sec_4_content_1:'To access the features offer by the Service, you will have to create a TADOTV Account. You may never use another\'s account without permission. When creating your account, you must provide accurate and complete information. You agree to provide true and accurate information and to update it as necessary to keep it accurate.',
    sec_4_content_2:'Only you are allowed to access your account. You are solely responsible for the activity that occurs on your account. You must keep your account password secure and confidential and, other than sharing it with your parent or guardian if you are a minor, you should not tell anyone your password or let anyone else access your account. Sharing your password might lead to all of your posts, messages, and personal information being leaked or someone impersonating or pretending to be you. If you don\'t keep your password confidential or give it to anyone other than your parent or guardian, we are not responsible for anyone accessing or using your account, including reading or sending messages from the account.',
    sec_4_content_3:'You must notify TADOTV immediately of any breach of security or unauthorized use of your account using the contact information below. You should also immediately change your password. We may terminate your account or block you from accessing the Service if you broke the rules on keeping your password secure.',
    sec_4_content_4:'By creating Account or using the Service, you affirm that you are either over the age of majority in your country, an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into and comply with the terms and conditions in the Terms Of Use. In any case, you affirm that you are at least 13 years old. If you are under 13 years of age, you are not permitted to access or use the Service. If we become aware that you are using the Service even though you are under 13 and not permitted to do so, we will deactivate your account and attempt to block you from accessing the Service.',
    sec_4_content_5:'To provide you with ease of access to your account, we may implement technology that enables us to recognize your device and provides you with direct access to your account without requiring you to retype your password when you revisit the Service.',
    sec_4_content_6:'Non-registered users are able to access only the parts of the Service that are publicly available and do not enjoy all of the privileges of being a registered member. They are, however, subject to the Terms Of Use.',
    sec_4_content_7:'You can terminate this Terms of Use at any time and for any reason by requesting to delete your account.',
    sec_4_content_8:'TADOTV reserves the right to suspend or delete your account without giving prior notice in the event:',
    sec_4_content_8_1:'it becomes known that you have provided us with false information with regards to your account;',
    sec_4_content_8_2:'if we believed that you are violating or have violated the Terms of Use;',
    sec_4_content_8_3:'if we believed that you have violate  any applicable laws and regulations; and',
    sec_4_content_8_4:'your account is inactive for a period as deemed reasonable by TADOTV for security purposes.',
    sec_4_content_9:'You will not create another account if we had already terminated your account (unless your account was terminated due to inactiveness) without our written permission to do so.',
    sec_5_title:'TERMS OF USING OF THE SERVICE',
    sec_5_content_1:'Upon activating your account, you may select different contents according to individual interest, browse through homepage and watch trailers for the existing contents and coming soon contents, watch contents and interact to pick the desired storyline.',
    sec_5_content_2:'You are solely responsible for everything you do on the Service. When using the Service, you must not post, comment, or send anything which : ',
    sec_5_content_2_1:'intends to  harass, scare, or upset anyone;',
    sec_5_content_2_2:'uses rude words or intending to upset or embarrass anyone;',
    sec_5_content_2_3:'encourages dangerous or illegal activities or self-harm;',
    sec_5_content_2_4:'depitcs horrible, shocking or distressing things;',
    sec_5_content_2_5:'contains pornographic or sexually explicit or depicts graphic violence;',
    sec_5_content_2_6:'contains any threat of any kind, including threats of physical violence to yourself or others;',
    sec_5_content_2_7:'contains racist or discriminating based on someone\'s race, religion, age, gender, disability, sexuality or political elements;',
    sec_5_content_2_8:'is illegal, could expose TADOTV to legal liability, or encourages people to get involved in anything which is illegal (for example, drugs, violence, or crime);',
    sec_5_content_2_9:'violates any third party\'s rights, including breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property right;',
    sec_5_content_2_10:'is spaming, attempting to sell anything to other users, or competing with the business of TADOTV;',
    sec_5_content_2_11:'contains any computer virus or other malicious code designed to attack, damage, divert, take over, disable, overburden, or otherwise impair the Service;',
    sec_5_content_3:'We reserve the right, at any time and without prior notice, to remove or disable access to any content for any reason or no reason. Some of the reasons we may remove or disable access to content may include finding the content objectionable, in violation of the Term of Use or otherwise harmful to the Service or our users.',
    sec_5_content_4:'We reserve the right, at any time and without prior notice, to alter and/or delete any content (including video) made for and published on the Service for any reason or no reason whatsoever.',
    sec_5_content_5:'You acknowledge that we have no obligation to monitor your access to or use of the Service for violations of the Terms of Use, or to review or edit any content. However, we have the right to do so for the purpose of operating and improving the Service (including without limitation for fraud prevention, risk assessment, investigation and customer support purposes), to ensure your compliance with the Terms of Use and to comply with applicable law or the order or requirement of a court, consent decree, administrative agency or other governmental body. We can also block or respond to content that we determine is otherwise objectionable or as set forth in the Terms of Use. In addition, you acknowledge that we have your consent to monitor and block content that we consider to be harassing or bullying.',
    sec_5_content_6:'If you used a services or features that is operated by a third party and made available through our Service, each party\'s terms will govern the respective party\'s relationship with you. TADOTV is not responsible or liable for a third party\'s terms or actions taken under the third party\'s terms.',
    sec_5_content_7:'You will not use the Service in order to:',
    sec_5_content_7_1:'collects user content or information, or otherwise accesses the Service using automated means (such as harvesting bots, robots, spiders, or scrapers) without our prior permission;',
    sec_5_content_7_2:'violates any robot exclusion headers of the site, if any, or by passes or circumvents other measures employed to prevent or limit access to the Service;',
    sec_5_content_7_3:'share, recompile, decompile, disassamble, reverse engineers, or make or distribute any other form of, or any derivative work from, the Service;',
    sec_5_content_7_4:'to collect any personal or private information from other users or from the Service;',
    sec_5_content_7_5:'pretend to come from someone other than you, or where you are impersonating someone else;',
    sec_5_content_7_6:'to access areas or features of the Service that you are not authorized to.',
    sec_5_content_8:'Any software that we provide you may automatically download and install upgrades, updates, or other new features. You may be able to adjust these automatic downloads through your device\'s settings.',
    sec_5_content_9:'You may not copy, modify, distribute, sell, or lease any part of our Service, nor may you reverse engineer or attempt to extract the source code of that software, unless laws prohibit these restrictions or you have our written permission to do so.',
    sec_6_title:'LICENSE TO USE THE SERVICE',
    sec_6_content_1:'We grant you a personal, limited, revocable, non-exclusive and non-transferable license to access and use the Service only as expressly permitted in this Terms of Use. You shall not use the Service for any illegal purpose or in any manner inconsistent with the provisions of this Terms of Use. You may use information made available through the Service solely for your personal, non-commercial use. You may also download material displayed on the Service for personal, non-commercial use only, provided that you also retain all copyright and other proprietary notices contained on or in the materials. Except as expressly granted herein, you may not distribute, modify, transmit, reuse, re-post, or use the content or the Service for any purpose without our permission. Any violation by you of the license provisions contained in this Section 7 may result in the immediate termination of your right to use the Service, as well as potential liability for copyright infringement depending on the circumstances.',
    sec_6_content_2:'We reserve the right to change or discontinue any aspect of the Service at any time without notice to you. Upgrades or updates of the Service may be made available from time to time. We do this to improve the quality of the Service that we provide to you and other users. The software or the software application store that makes the software available for download may include functionality to automatically check for updates or upgrades to the software. Unless your device, its settings or computer software does not permit transmission or use of upgrades or updates, you agree that we, or the applicable software application store, may provide notice to you of the availability of such upgrades or updates and automatically push such upgrade or update to your device or computer from time-to-time. You may be required to install certain upgrades or updates to the software in order to continue to access or use the Service, or portions thereof (including upgrades or updates designed to correct issues with the Service). Any updates or upgrades provided to you by us under the Terms of Use shall be considered part of the Service.',
    sec_6_content_3:'If you submit material to the Service, unless we indicate otherwise, you grant us a perpetual (for questions you ask only), non-exclusive, royalty-free and fully-paid-up, and fully sub-licensable right to access, view, use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, copy, and display such material throughout the world in any form, media, or technology now known or hereafter developed. You also permit any other user to view, copy, access, store, or reproduce such material for that user\'s personal use. You grant us the right to use the name or username that you submit in connection with such material. You represent and warrant that you own or otherwise control all of the rights to the material that you submit; that the material you submit is truthful and accurate; and that use of the material you supply does not violate this Terms of Use, the rights of any third party, or any applicable laws. You unconditionally waive in favour of us all moral rights in respect of material you submit to the Service under any laws in force from time to time in any part of the world.',
    sec_7_title:'INTELLECTUAL PROPERTY RIGHTS',
    sec_7_content:'The Service is proprietary and protected by copyright laws, international treaty provisions, trademarks, service marks, and other intellectual property laws and treaties. The Service is also protected as a collective work or compilation under copyright and other laws and treaties. You agree to abide by all applicable copyright and other laws, as well as any additional copyright notices or restrictions contained in the Service. You acknowledge that the Service contains original works and have been developed, compiled, prepared, revised, selected, and arranged by TADOTV is a valuable intellectual property of TADOTV. You agree to protect the proprietary rights of TADOTV and all others having rights in the Service during and after the term of this agreement and to comply with all reasonable written requests made by TADOTV protect our contractual and statutory rights in the Service.\n' +
    'TADOTV is committed to protecting the intellectual property of others, and we ask the same of our users. TADOTV neither warrants nor represents that your use of materials displayed on the Service will not infringe rights of third parties not owned by or affiliated with TADOTV. You agree to immediately notify us upon becoming aware of any claim that the Service infringe upon any copyright, trademark, or other contractual or statutory rights to the contact information below. And if TADOTV becomes aware that one of our users has repeatedly infringed copyrights, we will take reasonable steps within our power to terminate the user\'s account. \n' +
    'Be noticed that all the video made for and published in TADOTV at all times shall be fully owned by TADOTV. TADOTV has the full right to alter and/or delete the video made for and published in TADOTV, and use it for TADOTV\'s own purpose. TADOTV shall be released from all responsibilities in relation with the video contents made for and published in TADOTV.',
    sec_8_title:'DISCLAIMER',
    sec_8_content:'YOU AGREE THAT THE SERVICE ARE PROVIDED "AS IS", "AS AVAILABLE", "WITH ALL FAULTS BASIS" AND TO THE FULLEST EXTENT PERMITTED BY LAW, TADOTV, OUR MANAGING MEMBERS, SHAREHOLDERS, ITS OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES AND AGENTS DISCLAIM WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. IN ADDITION, WHILE TADOTV ATTEMPTS TO PROVIDE A GOOD USER EXPERIENCE, WE DO NOT REPRESENT OR WARRANT THAT: (1) THE SERVICE WILL ALWAYS BE SECURE, ERROR-FREE, OR TIMELY ACCURATES; (2) THE SERVICE WILL ALWAYS FUNCTION WITHOUT DELAYS, DISRUPTIONS, OR IMPERFECTIONS; (3) ANY CONTENT, USER CONTENT, OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICE WILL BE TIMELY OR ACCURATE; (4) ANY PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER RESULTING FROM YOUR ACCESS TO AND USE OF OUR SERICES; (5) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN; (6) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR SERVICE; (7) ANY BUGS, VIRUSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY; AND/OR (8) ANY ERRORS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THIS SERVICE.\n' +
    'TADOTV DOES NOT RESPONSIBLE AND ASSUMES NO LIABILITY FOR ANY CONTENT THAT YOU, ANOTHER USER, OR A THIRD PARTY CREATES, UPLOADS, POSTS, COMMENTS, SENDS, RECEIVES, OR STORES ON OR THROUGH OUR SERVICE. YOU AGREE AND UNDERSTAND THAT YOU MAY BE EXPOSED TO CONTENT THAT MIGHT BE OFFENSIVE, ILLEGAL, MISLEADING, OR OTHERWISE INAPPROPRIATE, NONE OF WHICH TADOTV WILL BE RESPONSIBLE FOR.',
    sec_9_title:'LIMITATION OF LIABILITY',
    sec_9_content:'TADOTV, OUR MANAGING MEMBERS, SHAREHOLDERS, ITS OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES AND AGENTS WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR SUCH DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (2) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE; (3) PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR ACCESS TO AND USE OF OUR SERVICE; (4) THE CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON OR THROUGH THE SERVICE; (5) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN; (6) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR SERVICE; (7) ANY BUGS, VIRUSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY; AND/OR (8) ANY ERRORS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THIS SERVICE, EVEN IF TADOTV HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. \n' +
    'YOU SPECIFICALLY ACKNOWLEDGE THAT TADOTV SHALL NOT BE LIABLE FOR CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.',
    sec_10_title:'INDEMNITY',
    sec_10_content:'You agree to defend, indemnify and hold TADOTV, our managing members, shareholders, its officers, directors, employees, affiliates, and agents harmless, from and against any and all claims, damages (actual and/or consequential), obligations, actions, proceedings, demands, losses, liabilities, costs or debt, and expenses (including but not limited to legal fees) arising from: (1) your use of and access to the Service; (2) your violation of any term of these Terms of Use; (3) your violation of any third party right, including but not limited to any copyright, property rights, or privacy right; (4) your violation of any laws, rules, regulations, codes, statutes, ordinances or orders of any governmental and quasi-governmental authorities, including but not limited to all regulatory, administrative and legislative authorities; (5) any misrepresentation made by you; or (6) any claim that your Content caused damage to a third party. This defense and indemnification obligation will survive these Terms of Use and your use of the Service. TADOTV reserves the right to assume the exclusive defense and control of any matter subject to indemnification by you, and you will not in any event settle any claim without the prior written consent of TADOTV.',
    sec_11_title:'GENERAL',
    sec_11_content_1:'Governing Law and Jurisdiction. You agree that: (i) the Service shall be provided from Indonesia; (ii) the laws of Indonesia  apply to this Terms of Use, including any contractual or non-contractual matter or dispute arising out of or in connection with this Terms of Use, your access to and use of the Service, and the relationship between us and you; and (iii) any conflict that arises from or out of the Service or conflicts between us and you in relation to the Service will be settled and finally resolved by Badan Arbitrase Nasional Indonesia (BANI) Rules. Notwithstanding the foregoing, you agree that we may seek interim, preliminary or protective relief before the competent courts of any jurisdiction.',
    sec_11_content_2:'Data Charges. By using the Service, including certain features such as invite-a-friend, on your mobile phone (and/or any other device) you may be subject to charges by your Internet or mobile service provider, so check with them first if you are not sure, as you will be responsible for any such costs.',
    sec_11_content_3:'Entire Agreement and Waiver. This Terms of Use, together with the Privacy Policy, shall constitute the entire agreement between you and us concerning the Service. If, for any reason, any provision of this Terms of Use is declared to be illegal, invalid, void or otherwise unenforceable by a competent court of any jurisdiction, then to the extent that term is illegal, invalid, void or unenforceable, it shall be severed and deleted from this Terms of Use and the remainder of this Terms of Use shall survive, remain in full force and effect and continue to be binding and enforceable. No failure or delay by us in exercising any right, power or privilege under this Terms of Use shall operate as a waiver of such right or acceptance of any variation of this Terms of Use and nor shall any single or partial exercise by either party of any right, power or privilege preclude any further exercise of that right or the exercise of any other right, power or privilege.',
    sec_11_content_4:'No Third Party Rights. Nothing in this Terms of Use shall confer or purport to confer any rights on any other third party.',
    sec_11_content_5:'Restrictions on Assignment. You will not transfer any of your rights or obligations under this Terms of Use to anyone else without our written consent. All of our rights and obligations under the Terms of Use are freely assignable by us in connection with a merger, acquisition, consolidation, reorganization, sale of assets, by operation of law or otherwise.',
    sec_11_content_6:'Linking and Framing. You may not frame the Service.  You may link to the Service, provided that you acknowledge and agree that you will not link the Service to any website containing any inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful topic, name, material, or information or that violates any intellectual property, proprietary, privacy, or publicity rights. Any violation of this provision may, in our sole discretion, result in termination of your use of and access to the Service effective immediately.',
    contact_us:'CONTACT US',
    contact_us_content:'TADOTV welcomes comments, questions, concerns, or suggestions. Please send to '
  },
  privacy_policy:{
    title:'Privacy Policy',
    content_1:'TADOTV may collect, process, and store your personal information, which is not limited to the details submitted when creating accounts.',
    content_2:'You and TADOTV may collectively or separately involve in the marketing and advertisement to promote the Service. You hereby gives TADOTV your consent to use your personal information for the said marketing and advertisement purposes.',
    content_3:'TADOTV places its highest priority on the privacy of its users and promises to exercise the utmost care and attention regarding its security measures for the continued security of any and all user information. TADOTV doesn\'t sell or share any personal information about you with third parties.',
    content_4:'TADOTV may access, preserve, or disclose any of your information if we are required to do so by law, or if we believed in good faith that it is reasonably necessary (i) to respond to claims asserted against us or to comply with legal process (for example, subpoenas or warrants), including those issued by courts having jurisdiction over us or you, (ii) to enforce or administer our agreements with users, such as this Terms of Use; (iii) for fraud prevention, risk assessment, investigation, customer support, providing the Service or engineering support, or (iv) to protect the rights, property or safety of TADOTV, the users of the Service, or members of the public.',
    content_5:'In case of termination of account, TADOTV will delete your data as soon as reasonably practicable, but in certain cases limited types of data, including log files and backups, may take up to 90 days to be fully deleted. Please note that in the case that you deactivate your account, even after you remove information from your profile or deactivate your account, copies of such content may still be visible and/or accessed on the Internet to the extent such information has been previously shared with others, or to the extent such information has been shared with, indexed by or cached by search engines.  Similarly, if you have given third party applications or websites (e.g., social networks) access to your personal information they may keep that information.  We can not control this, nor do we accept any responsibility or liability for this.',
  },
}
export default en;
