import React, { Component } from 'react';
import LandingPage from './containers/LandingPage/LandingPage';
import Player from './containers/Player';
import { Router, Switch, Route } from 'react-router-dom';
import './assets/css/common.less'
import { createBrowserHistory } from 'history';

class App extends Component {
  render() {

    return (
      <Router history={createBrowserHistory()}>
        <Switch>
          <Route path="/watch/:slug" component={Player}/>
          <Route exact path="/:slug?" component={LandingPage}/>
        </Switch>
      </Router>
    );
  }
}

export default App;
