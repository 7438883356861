/**
 * Created by kuyarawa on 24/09/18.
 */

import React, {Component} from 'react'
import _ from 'lodash'
import {mapDimensionQuality, mapTextQuality, mapTextSubtitle} from "../utils/utils";
import checkedIcon from '../assets/images/tadoPlayer/Rounded Checkbox.png';

class SettingContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSettingContent: this.props.showSettingContent,
            showQualityContent: this.props.showQualityContent,
            showSubtitleContent: this.props.showSubtitleContent,
            qualityLevelList: [],
            subtitleList: [],
            qualityLevel: this.props.qualityLevel,
            subtitle: this.props.subtitle,
            qualityLevelLabel: this.props.qualityLevelLabel,
            subtitleLabel: this.props.subtitleLabel,
            lang: this.props.lang,
        }
        this.handlerQualityLevelContainer = this.handlerQualityLevelContainer.bind(this);
        this.handlerSubtitleContainer = this.handlerSubtitleContainer.bind(this);
        this.handlerBackToSetting = this.handlerBackToSetting.bind(this);

    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...!_.isEqual(props.qualityLevelList, state.qualityLevelList) ? {
                qualityLevelList: props.qualityLevelList
            }: {},
            ...!_.isEqual(props.subtitleList, state.subtitleList) ? {
                subtitleList: props.subtitleList
            }: {},
            ...!_.isEqual(props.showSettingContent, state.showSettingContent) ? {
                showSettingContent: props.showSettingContent
            }: {},
            ...!_.isEqual(props.showQualityContent, state.showQualityContent) ? {
                showQualityContent: props.showQualityContent
            }: {},
            ...!_.isEqual(props.showSubtitleContent, state.showSubtitleContent) ? {
                showSubtitleContent: props.showSubtitleContent
            }: {},
            ...!_.isEqual(props.qualityLevel, state.qualityLevel) ? {
                qualityLevel: props.qualityLevel
            }: {},
            ...!_.isEqual(props.subtitle, state.subtitle) ? {
                subtitle: props.subtitle
            }: {},
            ...!_.isEqual(props.qualityLevelLabel, state.qualityLevelLabel) ? {
                qualityLevelLabel: props.qualityLevelLabel
            }: {},
            ...!_.isEqual(props.subtitleLabel, state.subtitleLabel) ? {
                subtitleLabel: props.subtitleLabel
            }: {},
            ...!_.isEqual(props.lang, state.lang) ? {
                lang: props.lang
            }: {},
        }
    }

    componentDidMount() {}

    hideAllSettingControl(){
        this.props.hideAllSettingControl();
    }

    handlerQualityLevelContainer(){
        if(this.state.qualityLevelList['low']!==undefined){
            this.props.handlerQualityLevelContainer();
        }

    }
    handlerSubtitleContainer(){
        this.props.handlerSubtitleContainer();
    }

    handlerBackToSetting(){
        this.props.handlerBackToSetting();
    }

    handlerQualityLevel(event,index){
        if(index!==this.state.qualityLevel){
            let parentElm = null;
            if(event.target.parentNode.nodeName==='A'){
                parentElm = event.target.parentElement;
            }else{
                parentElm = event.target.parentElement.parentElement;
            }
            let QL = document.querySelectorAll('.quality-items');
            const QLength = QL.length;
            for(let i=0;i<QLength;i++){
                QL[i].classList.remove('active');
            }
            parentElm.classList.add('active');
            this.props.changeQualityLevels(index);
        }else{
            this.props.hideSettingWithTimeout();
        }

    }
    handlerSubtitle(event,lang){
        let parentElm = null;
        if(event.target.parentNode.nodeName==='A'){
            parentElm = event.target.parentElement;
        }else{
            parentElm = event.target;
        }
        let SL = document.querySelectorAll('.subtitle-item');
        const SLength = SL.length;
        for(let i=0;i<SLength;i++){
            SL[i].classList.remove('active');
        }
        parentElm.classList.add('active');
        this.props.changeSubtitle(lang);
    }

    render() {

        return (
            <div>
                <div className="setting-tab" style={{
                    display:(this.state.showSettingContent)?'block':'none'
                }}>
                    <div className="tab-title">{this.state.lang.settings}</div>
                    <ul>
                        <li>
                            <a href="#quality" className="quality-select" onClick={this.handlerQualityLevelContainer}
                                style={(this.state.qualityLevelList['low']===undefined)?{color:'gray'}:{}}
                            >
                                <span>{this.state.lang.video_quality}</span>
                                <span className="quality-selected">{this.state.qualityLevelLabel}</span>
                            </a>
                        </li>
                        <li>
                            <a href="#subs" className="subtitle-select" onClick={this.handlerSubtitleContainer}>
                                <span>Subtitle</span>
                                <span className="subtitle-selected">{this.state.subtitleLabel}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="quality-setting-tab" style={{
                    display:(this.state.showQualityContent)?'block':'none'
                }}>
                    <div className="tab-title">
                        <a href="#back" className="back-to-setting" onClick={this.handlerBackToSetting}>{this.state.lang.video_quality}</a>
                    </div>
                    <ul id="quality-list">
                        <li><a href="#quality"
                               className={`quality-items ${this.state.qualityLevel==='auto' || this.state.qualityLevelList[this.state.qualityLevel]===undefined?'active':''}`}
                               onClick={(event)=>{
                                   this.handlerQualityLevel(event,'auto');
                               }}><img alt="checked" src={checkedIcon}/>
                            <div className="quality-text"><span>{this.state.lang.automatic}</span></div>
                        </a></li>
                        {_.map(this.state.qualityLevelList, (opt, i) => {
                            return (i!=='auto' && i!=='anon')?<li key={i+'quality'}><a href="#quality"
                                                                                       className={`quality-items ${this.state.qualityLevelList[this.state.qualityLevel]===i?'active':''}`}
                                                                                       onClick={(event)=>{
                                                                                           this.handlerQualityLevel(event,i);
                                                                                       }}><img alt="checked"
                                                                                               src={checkedIcon}/>
                                <div className="quality-text">
                                    <span>{mapTextQuality(i,this.state.lang)}</span><span style={{display:i==='auto'?'none':'block'}}>{(Number.isInteger(opt)?opt:mapDimensionQuality(i))}p</span>
                                </div>
                            </a></li>:'';

                        })}
                    </ul>
                </div>
                <div className="subtitle-setting-tab" style={{
                    display:(this.state.showSubtitleContent)?'block':'none'
                }}>
                    <div className="tab-title">
                        <a href="#back" className="back-to-setting" onClick={this.handlerBackToSetting}>Subtitle</a>
                    </div>
                    <ul id="subtitle-list">
                        <li><a href="#subs"
                               className={`subtitle-item ${this.state.subtitle==='off'?'active':''}`}
                               onClick={(event)=>{
                                   this.handlerSubtitle(event,'off');
                               }}><img alt="checked" src={checkedIcon}/>
                            <span>{this.state.lang.off}</span>
                        </a></li>
                        {_.map(this.state.subtitleList, (opt, i) => {
                            return <li key={i+'subs'}><a href="#subs"
                                                         className={`subtitle-item ${this.state.subtitleList[i]===this.state.subtitle?'active':''}`}
                                                         onClick={(event)=>{
                                                             this.handlerSubtitle(event,this.state.subtitleList[i].lang);
                                                         }}><img alt="checked"
                                                                 src={checkedIcon}/>
                                <span>{mapTextSubtitle(this.state.subtitleList[i].lang,this.state.lang)}</span>
                            </a></li>;

                        })}
                    </ul>
                </div>
            </div>
        )
    }
}

export default SettingContent
