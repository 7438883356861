/**
 * Created by kuyarawa on 24/09/18.
 */

import React, {Component} from 'react'
import _ from 'lodash'
import './EpisodeList.less'
import BannerCloseIcon from '../assets/images/tadoPlayer/close-cross@2x.png'
import Tracking from '../utils/Tracking'

class EpisodeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile : this.props.isMobile,
            showList: this.props.showList,
            showingId:this.props.showingId,
            episodeList:this.props.episodeList,
            lang:this.props.lang,
            orientation:'landscape'
        }
        this.tracking = new Tracking();
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...!_.isEqual(props.showList, state.showList) ? {
                showList: props.showList
            }: {},
            ...!_.isEqual(props.showingId, state.showingId) ? {
                showingId: props.showingId
            }: {},
            ...!_.isEqual(props.episodeList, state.episodeList) ? {
                episodeList: props.episodeList
            }: {},
            ...!_.isEqual(props.lang, state.lang) ? {
                lang: props.lang
            }: {},
            ...!_.isEqual(props.isMobile, state.isMobile) ? {
                isMobile: props.isMobile
            }: {},
            ...!_.isEqual(props.orientation, state.orientation) ? {
                orientation: props.orientation
            }: {}
        }
    }

    componentDidMount() {

    }
    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextProps.showList, this.state.showList) || !_.isEqual(nextProps.orientation, this.state.orientation)
    }
    render() {
        return (
            <div id="episode-list" className={this.state.isMobile?'mobile':''} style={{display:this.state.showList?'block':'none'}}>
                <div className="list-bg">&nbsp;</div>
                <div className="list-container">
                    <div className="list-close">
                        <a href="#close" onClick={()=>{
                            this.props.close();
                        }}>
                            <img src={BannerCloseIcon} alt="close"/>
                        </a>
                        <h4>{this.state.lang.episode_list}</h4>
                    </div>
                    <ul className="list-movie">
                        {_.map(this.state.episodeList, (opt, i) => {
                            return  <li key={i+opt.id} className="list-movie-item" onClick={()=>{
                                if(!opt.current){
                                    this.props.playNextEpisode(opt.slug)
                                }
                            }}>
                                <div className="item-number">{i+1}.</div>
                                <div className="item-content">
                                    <div className="item-image">
                                        {opt.current?<div className={`ep-overlay ${this.state.orientation==='landscape' || !this.state.isMobile?'':'portrait'}`} >&nbsp;</div>:null}
                                        <img src={this.state.orientation==='landscape' || !this.state.isMobile?opt.thumbnail.desktop:opt.thumbnail.mobile}
                                             className={this.state.orientation==='landscape' || !this.state.isMobile?'':'portrait'}
                                             alt="thumb"/>
                                    </div>
                                    <div className={`item-text ${this.state.orientation==='landscape' || !this.state.isMobile?'':'portrait'}`}>
                                        <span>{opt.title}</span>
                                        <span><i className="fa fa-clock"></i> {opt.duration}</span>
                                        <span>{opt.current ? this.state.lang.watching : ''}</span>
                                    </div>
                                </div>

                            </li>;
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}

export default EpisodeList
