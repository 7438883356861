import React from 'react';
import tadologoWhite from '../assets/images/tadoPlayer/tado-logo.png';
import InfoClip from './InfoClip'
import playButtonBig from '../assets/images/tadoPlayer/play-translucent.png';
import ReactImageFallback from 'react-image-fallback';
import loadingClip from '../assets/images/loading-clip-alt.gif';
import fallbackImage from '../assets/images/tadoPlayer/Mobile/fallback-image-poster@2x.png'
const initBackground = ()=>{
    return (<div style={{position:'fixed',width:'100%',height:'8%',top:'30%',display:'block',textAlign:'center',lineHeight:2}}>
        <span style={{fontSize:'14px',color:'black'}}>Loading Data </span><span><img alt="loading" src={loadingClip}/></span>
    </div>)
}
const InitPosterMobile = ({showInitialPoster, artwork,initialPlay,genre,series_name,title,duration,synopsis,start,thumbnail,load = false,orientation = 'potrait'}) => {
    if((thumbnail.mobile!==null || thumbnail.desktop!==null) && !load){
        return (
            <div id="poster-mobile-thumbnail" style={{
                display:(showInitialPoster)?'block':'none'
            }}>
                <div className="initial-play">
                    <a href="#play" id="init-play" onClick={() => initialPlay()} >
                        <img src={playButtonBig} alt="play" />
                    </a>
                </div>
                <ReactImageFallback
                    src={orientation==='landscape'?thumbnail.desktop:thumbnail.mobile}
                    fallbackImage={fallbackImage}
                    initialImage={initBackground()}
                    onError={()=>{
                        alert('Oops something wrong');
                    }}
                    alt="bg"
                    className="poster-bg" />
            </div>
        )
    }else if(!load){
        return (
            <div id="poster-container-mobile" className="poster-container" style={{
                display:(showInitialPoster)?'block':'none'
            }}>
                <div id="poster-mobile" className="poster" >
                    <img alt="Logo" src={tadologoWhite} className="tado-logo"/>
                    <div className="overlay"></div>
                    <div id="img-poster-mobile" className="img-poster" style={{background:'url(\''+artwork+'\') center center / cover no-repeat'}}>
                    </div>
                    <div className="play-area">
                        <a href="#play" id="play-poster" onClick={() => initialPlay()} >
                            <img alt="Logo" src={playButtonBig} /><br/>
                            <div className="play-text">{start}</div>
                        </a>
                    </div>
                    <InfoClip
                        genre={genre}
                        title={title}
                        series_name={series_name}
                        duration={duration}
                        synopsis={synopsis}
                    />
                </div>
            </div>
        )
    }else{
        return (null)
    }
}

export default InitPosterMobile;


