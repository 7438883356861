/**
 * Created by kuyarawa on 11/07/18.
 */

import React, {Component} from 'react'
import MetaTags from 'react-meta-tags';
import twitter from '../assets/icon/twitter@2x.png'
import fb from '../assets/icon/facebook@2x.png'
import instagram from '../assets/icon/instagram@2x.png'
import tadologo from '../assets/logo/big@2x.png'
import './LandingPage.css'

class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title:this.props.title,
            synopsis:this.props.synopsis,
            genre:"",
            artwork:this.props.artwork,
            series_name:this.props.series_name
        };
    }

    componentDidMount() {
        document.getElementById("landing-page").addEventListener("keydown", function(e) {
            // space and arrow keys
            const key = e.keyCode || e.which;
            if([32, 37, 38, 39, 40].indexOf(key) > -1) {
                e.preventDefault();
            }
        }, false);

    }

    render() {
        return (
            <div id="landing-page">
                <MetaTags>
                    <title>{(this.state.series_name!=="")?this.state.title+' - '+this.state.series_name:'TADOtv - '+this.state.title}</title>
                    <meta name="description" content={this.state.synopsis} />
                    <meta property="og:title" content={(this.state.series_name!=="")?this.state.title+' - '+this.state.series_name:'TADOtv - '+this.state.title} />
                    <meta property="og:image:secure_url" content={this.state.artwork} />
                    <meta property="og:description" content={this.state.synopsis} />
                </MetaTags>
                <div className="header">
                    <div className="container">
                        <div className="header-logo">
                            <a href={window.location.protocol+'//'+window.location.hostname}><img alt="Logo" src={tadologo}/></a>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div id="iframe-container">
                        <div className="iframe-video-container">
                            <div className="embed-responsive">
                                <iframe title="No Support" src={window.location.href} frameBorder="0" allowFullScreen>
                                    <p>No Support</p>
                                </iframe>
                            </div>
                        </div>
                        <footer><p>©PT Karya Anak Digital. 2018. Read our <a href="/privacy-policy" target="blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="/term-and-condition" target="blank" rel="noopener noreferrer">Terms & Conditions</a></p>
                            <div className="socmed-wrapper"><a href="https://www.facebook.com/TADO-TV-2274641872822097" target="_blank" rel="noopener noreferrer"><img src={fb} alt=""/></a>
                                <a href="https://twitter.com/tado_tv" target="_blank" rel="noopener noreferrer"><img src={twitter} alt=""/></a>
                                <a href="https://www.instagram.com/tado.tv" target="_blank" rel="noopener noreferrer"><img src={instagram} alt=""/></a>
                            </div>
                        </footer>
                    </div>
                </div>

            </div>
        );
    }
}

export default LandingPage;
