/**
 * Created by rinaldiguarsa on 20/06/19.
 */

import React from 'react'
import _ from 'lodash'
import './Home.less'
import logoBig from '../../assets/images/landing_page/TADO Logo Full.png'
import videoPath from '../../assets/video/horizontal video website (fine).mp4'
import videoPathMobile from '../../assets/video/Full video Website Vertical (fine).mp4'
import MobileDetect from '../../../node_modules/mobile-detect/';
import Tracking from '../../utils/Tracking'

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hidePlayButton:false,
            nav:this.props.nav,
            poster:true,
            hasStart:false,
            md:new MobileDetect(window.navigator.userAgent),
            showMenuMobile:false
        };
        this.hoverIn = this.hoverIn.bind(this);
        this.hoverOut = this.hoverOut.bind(this);
        this.toggleMenuMobile = this.toggleMenuMobile.bind(this);
    }

    componentDidMount() {
        this.tracking = new Tracking();
        this.players = document.querySelector("#home-video-content");
        this.players.play();
    }
    componentWillUnmount(){
        this.players.remove();
    }

    hoverIn(btn,color){
        const className = `.btn-${btn} .btn-line`;
        document.querySelector(className).style.borderColor = color;
    }
    hoverOut(btn){
        const className = `.btn-${btn} .btn-line`;
        document.querySelector(className).style.borderColor = '#ffffff';
    }
    toggleMenuMobile(){
        this.setState({
            showMenuMobile:!this.state.showMenuMobile
        })
    }

    render () {
        const nav = _.filter(this.state.nav,(opt)=>{
            return opt.link!=='home'
        })
        return (
            <div id="hm-home">
                <div className="home-title"  >
                    <div className="title-big">Interactive Storytelling Redefined</div>
                    <div className="title-small">We believe storytelling is the best way to communicate your ideas. Here we bring the innovation of interactive videos and turn it into a new experience for the mass.</div>
                </div>
                <div className="home-video-container">
                    <div className="home-video-overlay">&nbsp;</div>
                    <video id="home-video-content" className="home-video-content" muted={true} controls={false} autoPlay={true} loop={true} playsInline>
                        <source src={this.state.md.mobile()!==null?videoPathMobile:videoPath} type="video/mp4">
                        </source>

                    </video>
                    <div className="home-nav">
                        {_.map(nav, (opt, i) => {
                            return  <a key={i} href={opt.href ? opt.href : '#nav'} target={opt.href ? '_blank': ''}><button key={i} className={`home-nav-button btn-${opt.className}`}
                                            onMouseEnter={()=>this.hoverIn(opt.className,opt.lineColorHover)}
                                            onMouseLeave={()=>this.hoverOut(opt.className)}
                                            onClick={()=>{
                                                // window.location.href='/'+opt.link;
                                                this.props.clickMenu(opt.link);
                                            }}
                            >
                                <div className="btn-line">&nbsp;</div>
                                <span>{opt.name}</span>
                            </button></a>
                        })}
                    </div>
                    <button className="home-start"
                        onClick={this.props.toggleMenuMobile}
                    >
                        <i className="fa fa-bars"></i><br />
                        Start
                    </button>
                </div>
            </div>
        )
    }
}

export default Home;
