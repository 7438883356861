/**
 * Created by kuyarawa on 21/07/18.
 */

import React from 'react'
import './TermPolicy.less'
import twitter from '../../assets/icon/twitter@2x.png'
import fb from '../../assets/icon/facebook@2x.png'
import instagram from '../../assets/icon/instagram@2x.png'
import Lang from '../../utils/Lang'
class TermCondition extends React.Component {

    constructor(props) {
        super(props);
        this.lang = new Lang();
        const lang = this.lang.translate().term_condition;
        this.state = {
            lang:lang,
            langId:'id',

        };
        this.changeLang = this.changeLang.bind(this);
    }

    componentDidMount() {

    }

    changeLang(langId){
        const lang = this.lang.translate(langId).term_condition;
        this.setState({
            lang:lang,
            langId:langId
        });
    }

    getActiveClass(langId){
        return this.state.langId===langId?'active':''
    }

    render () {
        return (
            <div id="term-condition">
                <div className="header">
                    <h3>Terms & Conditions</h3>
                    <div className="change-lang">
                        <a href="#lang" className={this.getActiveClass('id')} onClick={()=>this.changeLang('id')}>Bahasa</a> | <a href="#lang" className={this.getActiveClass('en')} onClick={()=>this.changeLang('en')}>English</a>
                    </div>
                </div>
                <div className="content">
                    <h4>{this.state.lang.sec_1_title}</h4>
                    <p>{this.state.lang.sec_1_content}</p>
                    <h4>{this.state.lang.sec_2_title}</h4>
                    <ol>
                        <li>{this.state.lang.sec_2_content_1}</li>
                        <li>{this.state.lang.sec_2_content_2}</li>
                        <li>{this.state.lang.sec_2_content_3}</li>
                    </ol>
                    <h4>{this.state.lang.sec_3_title}</h4>
                    <ol>
                        <li>{this.state.lang.sec_3_content_1}</li>
                        <li>{this.state.lang.sec_3_content_2}</li>
                    </ol>
                    <h4>{this.state.lang.sec_4_title}</h4>
                    <ol>
                        <li>{this.state.lang.sec_4_content_1}</li>
                        <li>{this.state.lang.sec_4_content_2}</li>
                        <li>{this.state.lang.sec_4_content_3}</li>
                        <li>{this.state.lang.sec_4_content_4}</li>
                        <li>{this.state.lang.sec_4_content_5}</li>
                        <li>{this.state.lang.sec_4_content_6}</li>
                        <li>{this.state.lang.sec_4_content_7}</li>
                        <li>{this.state.lang.sec_4_content_8}
                            <ul>
                                <li>{this.state.lang.sec_4_content_8_1}</li>
                                <li>{this.state.lang.sec_4_content_8_2}</li>
                                <li>{this.state.lang.sec_4_content_8_3}</li>
                                <li>{this.state.lang.sec_4_content_8_4}</li>
                            </ul>
                        </li>
                        <li>{this.state.lang.sec_4_content_9}</li>
                    </ol>
                    <h4>{this.state.lang.sec_5_title}</h4>
                    <ol>
                        <li>{this.state.lang.sec_5_content_1}</li>
                        <li>{this.state.lang.sec_5_content_2}
                            <ul>
                                <li>{this.state.lang.sec_5_content_2_1}</li>
                                <li>{this.state.lang.sec_5_content_2_2}</li>
                                <li>{this.state.lang.sec_5_content_2_3}</li>
                                <li>{this.state.lang.sec_5_content_2_4}</li>
                                <li>{this.state.lang.sec_5_content_2_5}</li>
                                <li>{this.state.lang.sec_5_content_2_6}</li>
                                <li>{this.state.lang.sec_5_content_2_7}</li>
                                <li>{this.state.lang.sec_5_content_2_8}</li>
                                <li>{this.state.lang.sec_5_content_2_9}</li>
                                <li>{this.state.lang.sec_5_content_2_10}</li>
                                <li>{this.state.lang.sec_5_content_2_11}</li>
                            </ul>
                        </li>
                        <li>{this.state.lang.sec_5_content_3}</li>
                        <li>{this.state.lang.sec_5_content_4}</li>
                        <li>{this.state.lang.sec_5_content_5}</li>
                        <li>{this.state.lang.sec_5_content_6}</li>
                        <li>{this.state.lang.sec_5_content_7}
                            <ul>
                                <li>{this.state.lang.sec_5_content_7_1}</li>
                                <li>{this.state.lang.sec_5_content_7_2}</li>
                                <li>{this.state.lang.sec_5_content_7_3}</li>
                                <li>{this.state.lang.sec_5_content_7_4}</li>
                                <li>{this.state.lang.sec_5_content_7_5}</li>
                                <li>{this.state.lang.sec_5_content_7_6}</li>
                            </ul>
                        </li>
                        <li>{this.state.lang.sec_5_content_8}</li>
                        <li>{this.state.lang.sec_5_content_9}</li>
                    </ol>
                    <h4>{this.state.lang.sec_6_title}</h4>
                    <ol>
                        <li>{this.state.lang.sec_6_content_1}</li>
                        <li>{this.state.lang.sec_6_content_2}</li>
                        <li>{this.state.lang.sec_6_content_3}</li>
                    </ol>
                    <h4>{this.state.lang.sec_7_title}</h4>
                    <p>{this.state.lang.sec_7_content}</p>
                    <h4>{this.state.lang.sec_8_title}</h4>
                    <p>{this.state.lang.sec_8_content}</p>
                    <h4>{this.state.lang.sec_9_title}</h4>
                    <p>{this.state.lang.sec_9_content}</p>
                    <h4>{this.state.lang.sec_10_title}</h4>
                    <p>{this.state.lang.sec_10_content}</p>
                    <h4>{this.state.lang.sec_11_title}</h4>
                    <ol>
                        <li>{this.state.lang.sec_11_content_1}</li>
                        <li>{this.state.lang.sec_11_content_2}</li>
                        <li>{this.state.lang.sec_11_content_3}</li>
                        <li>{this.state.lang.sec_11_content_4}</li>
                        <li>{this.state.lang.sec_11_content_5}</li>
                        <li>{this.state.lang.sec_11_content_6}</li>
                    </ol>
                    <h4 className="contact-us">{this.state.lang.contact_us}</h4>
                    <p>{this.state.lang.contact_us_content}<a href="mailto:kami.tadotv@gmail.com">kami.tadotv@gmail.com</a></p>
                </div>
                <footer>
                    <p>©PT Karya Anak Digital. 2018. Read our <a href="privacy-policy" target="blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="term-and-condition" target="blank" rel="noopener noreferrer">Terms & Conditions</a></p>
                    <div className="socmed-wrapper">
                        <a href="https://www.facebook.com/TADO-TV-2274641872822097" target="_blank" rel="noopener noreferrer"><img src={fb} alt="logo"/></a>
                        <a href="https://twitter.com/tado_tv" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="logo"/></a>
                        <a href="https://www.instagram.com/tado.tv/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="logo"/></a>
                    </div>
                </footer>
            </div>
        )
    }
}

export default TermCondition;
