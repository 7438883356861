import CONFIG from '../constants/config'
import {axiosClientTadoPlayer} from "./axiosClient";
const GOOGLE_MAP_URL = 'https://maps.google.com/maps/api/geocode/json';
const apiKey = CONFIG.gmapsApiKey;
class Geocoding{

    constructor(debug = false){
        this.debug = debug;
    }

    log(msg,val){
        if(this.debug){
            console.log(msg,val);
        }
    }

    async handleUrl(url){
        const response = await fetch(url).catch(error =>
            Promise.reject(new Error("Error fetching data"))
        );

        const json = await response.json().catch(() => {
            this.log("Error parsing server response");
            return Promise.reject(new Error("Error parsing server response"));
        });

        if (json.status === "OK") {
            this.log(json);
            return json;
        }
        this.log(`Server returned status code ${json.status}`, true);
        return Promise.reject(
            new Error(`Server returned status code ${json.status}`)
        );
    }

    async getFromCoordinates(lat,lng){
        if (!lat || !lng) {
            this.log("Provided coordinates are invalid", true);
            return Promise.reject(new Error("Provided coordinates are invalid"));
        }
        const latLng = `${lat},${lng}`;
        let url = `${GOOGLE_MAP_URL}?latlng=${encodeURI(latLng)}`;
        url += `&key=${apiKey}`;
        return this.handleUrl(url);
    }

    navigation(){
        const _this = this;
        return new Promise(function (resolve, reject) {
            if (navigator && navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position)=>{
                    _this.log('current position',position);
                    resolve (position);
                }, ()=>{
                    _this.log('Blocked Geolocation');
                    reject(new Error("Blocked Geolocation"));
                });
            } else {
                _this.log('Not Supported');
                reject(new Error("Not Supported"));
            }
        });
    }

    getFromIp(){
        return axiosClientTadoPlayer['get']('mobileweb/v1.6/platform/geolocation')
    }

    getCountryId(){
        const _this = this;
        return new Promise(function (resolve, reject) {
            _this.getFromIp().then((response)=>{
                _this.log('response',response.data);
                try {
                    resolve(response.data.data.country_code.toUpperCase());
                }
                catch(error) {
                    _this.log("not found location");
                    resolve(null);
                }
            },()=>{
                resolve(null);
            })
        });

    }
}
export default Geocoding;
