/**
 * Created by kuyarawa on 24/09/18.
 */

import React, {Component} from 'react'
import _ from 'lodash'
import CONFIG from "../constants/config";
import './Share.less';
import Line from '../assets/images/tadoPlayer/line-button@2x.png';
import Facebook from '../assets/images/tadoPlayer/facebook-button@2x.png';
import Twitter from '../assets/images/tadoPlayer/twitter-button@2x.png';
import Whatsapp from '../assets/images/tadoPlayer/whatsapp-button@2x.png';

const MODAL_STYLE = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width                 : window.innerWidth < 1000 ? '80%': '1000px',
        borderRadius          : '4px'
    }
};
class Share extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slug: "",
            showModal:this.props.showModal,
            showEmbedTag:false,
            isMobile:this.props.isMobile,
            langText:this.props.langText.share
        }
        this.closeModal = this.closeModal.bind(this);
        this.copyText = this.copyText.bind(this);
        this.handlerEmbedTag = this.handlerEmbedTag.bind(this);

    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...!_.isEqual(props.showModal, state.showModal) ? {
                showModal: props.showModal
            }: {},
            ...!_.isEqual(props.slug, state.slug) ? {
                slug: props.slug
            }: {},
            ...!_.isEqual(props.langText, state.langText.share) ? {
                langText: props.langText.share
            }: {},
            ...!_.isEqual(props.isMobile, state.isMobile) ? {
                isMobile: props.isMobile
            }: {}
        }
    }

    componentDidMount() {
        this.setState({
            showEmbedTag:false,
        })
    }

    closeModal(event) {
        if(event.target.id==='share-modal'){
            this.props.handlerShare();
        }

    }

    handlerEmbedTag(){
        this.setState({
            showEmbedTag:!this.state.showEmbedTag
        })
    }

    copyText(event){
        const input = event.target.parentNode.previousSibling;
        const el = document.createElement('input');
        el.value = input.value;
        el.style.fontSize = '16px';
        document.body.appendChild(el);
        try {
            if(this.state.isMobile && navigator.userAgent.match(/ipad|ipod|iphone/i)){
                const range = document.createRange();
                el.contentEditable = true;
                el.readOnly = false;
                range.selectNodeContents(el);

                const s = window.getSelection();
                s.removeAllRanges();
                s.addRange(range);
                el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
                document.execCommand('copy');
            }else{
                el.select();
                document.execCommand('copy');
            }
        }
        catch (err) {
            console.log(err,'please press Ctrl/Cmd+C to copy');
        }
        input.blur();
        el.blur();
        document.body.removeChild(el);

        const prev = event.target.previousSibling;
        if(this.state.isMobile){
            prev.parentNode.style.background = '#eee';
        }
        prev.style.display = 'inline-block';
        setTimeout(()=>{
            if(this.state.isMobile){
                prev.parentNode.style.background = 'linear-gradient(270deg, #eee 35%, transparent 99%)';
            }
            prev.style.display = 'none';
        },1000);
    }

    desktopShare(){
        const shareUrl = CONFIG.shareUrl+this.state.slug;
        const tagEmbed = '<iframe title="tadoTV" src="'+window.location.protocol+'//'+window.location.hostname+'/watch/'+this.state.slug+'" width="853" height="487" frameBorder="0" allowFullScreen><p>No Support</p></iframe>'
        const fbShare = 'http://www.facebook.com/sharer/sharer.php?u='+shareUrl;
        const twitterShare = 'https://twitter.com/home?status='+shareUrl;
        return (
        <div id="desktop-share">
            <div className="action">
                <button className="embed" type="button" onClick={this.handlerEmbedTag}><i className="fa fa-code"></i> {this.state.langText.embed_content}</button>
                <a href={fbShare} target="_blank" rel="noopener noreferrer" className="facebook"><i className="fab fa-facebook-square"></i> {this.state.langText.share_fb}</a>
                <a href={twitterShare} target="_blank" rel="noopener noreferrer" className="twitter"><i className="fab fa-twitter-square"></i> {this.state.langText.share_twitter}</a>
            </div>
            <div className="embed-content-tag" style={{
                display:(this.state.showEmbedTag)?'block':'none'
            }} >
                <h3>{this.state.langText.copy_tag}:</h3>
                <div className="input-copied">
                    <input type="text" className="form-control" name="share" defaultValue={tagEmbed} readOnly={true} onFocus={(event)=>{event.target.blur()}} />
                    <div className="text">
                        <span>{this.state.langText.copy_clipboard}</span>
                        <a href="#copy" onClick={this.copyText}>{this.state.langText.copy}</a>
                    </div>
                </div>
                <p>{this.state.langText.share_term}<a href="/term-and-condition" target="_blank" rel="noopener noreferrer" >{this.state.langText.share_term_2}</a></p>
            </div>
        </div>
        )
    }

    mobileShare(){
        const shareUrl = CONFIG.shareUrl+this.state.slug;
        const fbShare = 'http://www.facebook.com/sharer/sharer.php?u='+shareUrl;
        const twitterShare = 'https://twitter.com/intent/tweet?text='+shareUrl;
        const lineShare = 'https://line.me/R/msg/text/?'+shareUrl;
        const whatsappShare = 'whatsapp://send?text='+shareUrl;
        return (
            <div id="mobile-share">
                <div className="action">
                    <a href={fbShare} target="_blank"  className="facebook"><img alt="logo" src={Facebook}/></a>
                    <a href={twitterShare} target="_blank"  className="twitter"><img alt="logo" src={Twitter}/></a>
                    <a href={lineShare} target="_blank"  className="twitter"><img alt="logo" src={Line}/></a>
                    <a href={whatsappShare} target="_blank" className="twitter"><img alt="logo" src={Whatsapp}/></a>
                </div>
            </div>
        )
    }

    render() {
        const shareUrl = CONFIG.shareUrl+this.state.slug;
        return (
            <div id="share-modal" style={{display:this.state.showModal?'block':'none'}} onClick={this.closeModal}>
                <div className="share-modal-container box box-default" style={MODAL_STYLE.content}>
                    <div className="box-header with-border">
                        <h3><i className="fa fa-share-alt"></i> {this.state.langText.share_content}</h3>
                    </div>
                    <div className="box-body">
                        <div id="web-share">
                            <h3>{this.state.langText.copy_link}</h3>
                            <div className="input-copied">
                                <input type="text" className="form-control" name="share" defaultValue={shareUrl} readOnly={true} style={{fontSize:'16px !important'}} />
                                <div className="text">
                                    <span>{this.state.langText.copy_clipboard}</span>
                                    <a href="#copy" onClick={(event)=>this.copyText(event)}>{this.state.langText.copy}</a>
                                </div>

                            </div>
                            <h3>{this.state.langText.or_share}:</h3>
                            {!this.state.isMobile?this.desktopShare():this.mobileShare()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Share
