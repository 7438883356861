const id = {
    start:"Mulai",
    general:{
        seconds:'Detik',
        video_played:'Video Diputar',
        video_paused:'Video Dijeda',
        loading_text_start:'Ide bagus, mari lihat kemana kita akan dibawa',
        loading_text_playing:'Sedang memasak sesuatu',
        rotate_info_title:'Putar ponsel Anda untuk layar penuh',
        rotate_info_subtitle:'Harap pastikan orientasi ponsel Anda tidak terkunci',
    },
    share:{
        embed_content:"Sematkan Konten",
        share_fb:"Bagikan di Facebook",
        share_content:"Bagikan Konten",
        share_twitter:"Bagikan di Twitter",
        copy_clipboard:"Tautan berhasil disalin!",
        copy_tag:"Salin Tag",
        share_term:"Dengan menyematkan konten TADOtv di situs Anda, Anda menyetujui ",
        share_term_2:"Ketentuan Layanan API TADOtv",
        copy:"Salin",
        copy_link:"Salin Tautan",
        or_share:"Atau bagikan ke",
    },
    setting:{
        settings:"Pengaturan",
        video_quality:"Kualitas Video",
        automatic:"Otomatis",
        low:"Rendah",
        basic:"Dasar",
        high:"Tinggi",
        ultra:"Sangat Tinggi",
        off:"Nonaktif",
        bahasa:"Bahasa Indonesia",
        english:"Bahasa Inggris",
        sound_on:'Terdengar',
        sound_off:'Dimatikan',
    },
    end_poster:{
        last_checkPoint:"Opsi Sebelumnya",
        replay_movie:"Tonton Ulang",
        next_episode:"Episode Berikutnya",
        enjoy_tado:"Nikmati konten video interaktif lainnya dengan mengunduh aplikasi TADOtv",
    },
    series:{
        episode_list:"Daftar Episode",
        watching:"Sedang Menonton"
    },
    bridge:{
        watch_tado:"Tonton pilihan ini secara eksklusif di aplikasi TADOtv"
    },
    banner:{
        title:"TADOtv - Konten Video Interaktif",
        subtitle:"Tonton video interaktif dengan mendownload aplikasi TADOtv",
        install:"Pasang",
    },
    term_condition:{
        title:'Terms & Conditions',
        sec_1_title:'SYARAT DAN KETENTUAN PENGGUNAAN',
        sec_1_content:'Syarat - syarat dan ketentuan - ketentuan penggunaan ini ("Syarat dan Ketentuan Penggunaan") disusun untuk mengatur hubungan antara PT Karya Anak Digital ("TADOTV", "kami", "milik kami", atau "pada kami") dan penggunanya ("para pengguna", "pengguna", atau "anda") yang timbul dari diaksesnya atau penggunaan situs kami, layanan - layanan kami, atau aplikasi - aplikasi (termasuk aplikasi seluler) yang dibuat dan disediakan oleh TADOTV, dimana TADOTV adalah sebuah platform video vertikal interaktif, dimana pengguna dapat menikmati, menontonton, dan menentukan alur cerita dari video yang ditonton (bersama - sama, "Layanan"). Jika anda merupakan anak di bawah umur di negara anda dan anda bukanlah anak - anak yang dibebaskan pengasuhan dari orang tuanya (di negara yang mengizinkan hal ini), anda harus  mendapatkan pesetujuan dari orang tua atau wali anda untuk dapat menyetujui Syarat dan Ketentuan Penggunaan ini dan menggunakan Layanan, dan dengan menggunakan Layanan, anda menyatakan bahwa anda telah mendapatkan persetujuan tersebut.',
        sec_2_title:'PERSETUJUAN ATAS SYARAT DAN KETENTUAN PENGGUNAAN',
        sec_2_content_1:'Dengan mengakses dan menggunakan Layanan (baik anda terdaftar sebagai anggota atau tidak), anda \tmengikatkan diri ke dalam perjanjian hukum yang mengikat dengan PT Karya Anak Digital beralamat di Podomoro \tCity Ruko Garden Shopping Arcade Blok B/8 DH, Jl. S. Parman, Tanjung Duren Selatan, Grogol Petamburan, Jakarta \tBarat, Indonesia.',
        sec_2_content_2:'Dengan mengakses, menggunakan, mendaftarkan, atau menerima salah satu Layanan manapun, dengan cara a\tpapun di akses, anda setuju untuk terikat terhadap Syarat dan Ketentuan Penggunaan ini beserta Kebijakan \tKerahasiaan dari TADOTV, yang dimasukkan ke dalam Syarat dan Ketentuan Penggunaan ini sebagai referensi. \tSyarat dan Ketentuan Penggunaan ini akan mempengaruhi hak dan kewajiban hukum anda.',
        sec_2_content_3:'Anda setuju untuk hanya mengakses atau menggunakan Layanan sesuai dengan Syarat dan Ketentuan \tPenggunaan ini. Apabila anda tidak dapat menerima dan menyetujui Syarat dan Ketentuan Penggunaan ini, anda \ttidak diperkenankan untuk mengakses atau menggunakan Layanan.',
        sec_3_title:'SYARAT DAN KETENTUAN PENGGUNAAN TERPISAH DAN PERUBAHANNYA',
        sec_3_content_1:'Ada saatnya dimana kami akan menawarkan fitur khusus yang memiliki syarat-syarat dan ketentuan-ketentuan \ttersendiri disamping Syarat dan Ketentuan Penggunaan ini ("Syarat dan Ketentuan Penggunaan Terpisah"). Dalam \thal tersebut, Syarat dan Ketentuan Penggunaan Terpisah yang akan berlaku apabila terdapat ketidaksesuaian \tdengan Syarat dan Ketentuan Penggunaan ini.',
        sec_3_content_2:'TADOTV dapat melakukan perubahan terhadap Syarat dan Ketentuan Penggunaan dan Syarat dan Ketentuan \tPenggunaan Terpisah apabila dianggap perlu, tanpa ada pemberitahuan terlebih dahulu kepada para pengguna. \tPerubahan tersebut akan berlaku efektif pada saat Syarat dan Ketentuan Penggunaan atau Syarat dan Ketentuan \tPenggunaan Terpisah yang telah diubah dimuat di lokasi yang seharusnya pada situs atau aplikasi yang \tdioperasikan oleh TADOTV. Para pengguna dianggap telah memberikan persetujuan yang sah dan tidak dapat \tditarik kembali atas perubahan Syarat dan Ketentuan Penggunaan atau Syarat dan Ketentuan Penggunaan Terpisah \tdengan melanjutkan penggunaan Layanan. Para pengguna diharapkan melakukan pengecekan Syarat dan \tKetentuan Penggunaan secara berkala selama menggunakan Layanan, karena pemberitahuan terpisah akan \tperubahan Syarat dan Ketentuan Penggunaan mungkin tidak tersedia.',
        sec_4_title:'AKUN TADOTV',
        sec_4_content_1:'Untuk mendapatkan semua fitur yang disediakan oleh Layanan, anda harus membuat Akun TADOTV terlebih \tdahulu. Anda tidak diperkenankan untuk menggunakan akun pihak lain tanpa izin. Anda diharuskan memberikan \tdata-data dan informasi yang akurat dan lengkap dalam pembuatan akun. Anda setuju untuk memberikan data - \tdata dan informasi yang benar dan akurat serta bersedia untuk melakukan pembaruan sebagaimana diperlukan \tagar data - data dan informasi tersebut tetap akurat.',
        sec_4_content_2:'Hanya anda sendiri yang diperkenankan untuk mengakses akun anda. Anda bertanggung jawab penuh atas seluruh \taktivitas yang melibatkan akun anda. Anda diharuskan untuk menjaga keamanan dan kerahasiaan kata kunci akun \tanda dan anda tidak diperkenankan untuk memberitahukan kata kunci anda kepada pihak lain, selain daripada \torang tua atau wali anda apabila anda merupakan anak di bawah umur, atau membiarkan pihak lain mengakses \takun anda.  Berbagi kata kunci dapat menyebabkan terjadinya kebocoran atas seluruh tautan, pesan, dan informasi \tpribadi anda atau  terdapat pihak lain yang meniru atau berpura-pura menjadi anda. Apabila  anda tidak dapat\tmenjaga kerahasiaan kata kunci anda atau memberikannya kepada pihak lain selain dari orang tua atu wali anda, \tkami tidak bertanggung jawab apabila ada pihak lain yang mengakses atau menggunakan akun anda, termasuk \tmembaca atau mengirim pesan dari akun anda.',
        sec_4_content_3:'Anda harus segera memberitahu TADOTV apabila terdapat pelanggaran keamanan atau penggunaan akun secara \ttidak sah melalui alamat yang tertera dibawah ini. Anda juga diharuskan untuk segera melakukan perubahan atas \tkata kunci anda. Kami berhak untuk menghentikan akun anda atau memblokir akses anda terhadap Layanan apabila \tanda melanggar ketentuan dalam menjaga keamanan kata kunci anda.',
        sec_4_content_4:'Dengan membuat akun atau dengan menggunakan Layanan, anda menegaskan bahwa anda adalah telah cukup \tumur di negara anda, anak - anak yang dibebaskan dari pengasuhan orang tua, atau memiliki izin dari orang tua \tatau wali yang sah, dan mampu secara penuh dan cakap untuk dapat tunduk dan memenuhi semua syarat dan \tketentuan yang terdapat di dalam Syarat dan Ketentuan Penggunaan ini. Dalam segala hal, anda menegaskan \tbahwa anda telah berusia minimal 13 tahun. Apabila anda berusia di bawah 13 tahun, anda tidak diperkenankan \tuntuk mengakses atau menggunakan Layanan. Apabila diketahui oleh kami bahwa anda menggunakan Layanan \tsedangkan anda berusia di bawah 13 tahun dan tidak diperkenankan untuk melakukan hal tersebut, kami akan \tmenutup akun anda dan menghalangi anda untuk dapat mengakses Layanan.',
        sec_4_content_5:'Agar anda dapat dengan mudah mengakses akun anda, kami dapat menerapkan teknologi yang memungkinkan \tkami untuk mengenali perangkat yang anda gunakan dan memberikan anda akses langsung ke akun anda, tanpa \tanda harus mengetik ulang kata kunci anda pada saat anda menggunakan kembali Layanan.',
        sec_4_content_6:'Pengguna yang tidak terdaftar hanya dapat mengakses sebagian Layanan yaitu yang tersedia untuk umum dan \ttidak dapat menikmati seluruh hak - hak istimewa yang dimiliki oleh anggota yang terdaftar. Namun demikian, \tmereka tetap tunduk terhadap Syarat dan Ketentuan Penggunaan ini.',
        sec_4_content_7:'Anda dapat memutuskan diri dari Syarat dan Ketentuan Penggunaan ini kapan saja dan dengan alasan apapun \tdengan cara menghapus akun anda.',
        sec_4_content_8:'TADOTV berhak untuk menangguhkan atau menghapus akun anda tanpa adanya pemberitahuan terlebih dahulu \tdalam keadaan :',
        sec_4_content_8_1:'Diketahui bahwa anda telah memberikan data atau informasi yang salah berkaitan dengan akun anda;',
        sec_4_content_8_2:'Jika kami yakin bahwa anda melanggar atau telah melanggar Syarat dan Ketentuan Penggunaan ini;',
        sec_4_content_8_3:'Jika kami yakin bahwa anda telah melanggar peraturan atau hukum yang berlaku ; dan',
        sec_4_content_8_4:'Apabila akun anda sudah tidak aktif digunakan selama suatu waktu sebagaimana dianggap wajar oleh \t\tTADOTV untuk tujuan keamanan.',
        sec_4_content_9:'Anda tidak diperkenankan untuk membuat akun baru apabila kami telah menghentikan akun anda (kecuali akun \tanda dihentikan karena tidak aktif) tanpa adanya izin tertulis dari kami.',
        sec_5_title:'KETENTUAN PENGGUNAAN LAYANAN',
        sec_5_content_1:'Setelah mengaktifasi akun anda, anda dapat memilih konten yang berbeda sesuai dengan minat masing-masing, \tmelihat cuplikan konten yang sedang tayang dan akan datang, menonton video dan memilih jalur cerita yang \tdinginkan.',
        sec_5_content_2:'Anda bertanggung jawab sepenuhnya atas semua hal yang anda tulis atau tautkan di dalam Layanan. Selama \tmenggunakan Layanan, anda tidak diperkenankan untuk menautkan, memberikan komentar, atau mengirimkan hal-\thal yang :',
        sec_5_content_2_1:'bermaksud untuk melecehkan, menakut-nakuti, atau membuat marah pihak lain;',
        sec_5_content_2_2:'menggunakan kata-kata yang kasar atau bermaksud untuk membuat marah atau mempermalukan pihak lain;',
        sec_5_content_2_3:'mendorong kegiatan yang berbahaya atau ilegal atau aktifitas yang dapat merugikan diri sendiri;',
        sec_5_content_2_4:'berisi hal-hal yang menakutkan, mengejutkan, atau menyedihkan;',
        sec_5_content_2_5:'mengandung hal-hal yang bersifat pornografi atau aktifitas seksual secara terang-terangan atau hal - hal yang \t\tmenunjukan tindak kekerasan;',
        sec_5_content_2_6:'mengandung ancaman dalam bentuk apapun, termasuk kekerasan fisik terhadap diri anda sendiri atau pun \t\torang lain;',
        sec_5_content_2_7:'mengandung diskriminasi terhadap ras, etnis, agama, kepercayaan, umur, jenis kelamin, disabilitas, seksualitas, \t\tatau unsur-unsur politis seseorang;',
        sec_5_content_2_8:'bersifat ilegal dan dapat menyebabkan TADOTV untuk bertanggung jawab secara hukum atau dapat \t\tmenyebabkan seseorang untuk melakukan hal-hal yang ilegal (misalnya, narkoba, tindak kekerasan, atau tindak \t\tkejahatan);',
        sec_5_content_2_9:'melanggar hak-hak dari pihak ketiga, termasuk di dalamnya pelanggaran kepercayaan, hak cipta, merek, paten, \t\trahasia dagang, hak moral, hak privasi, hak publikasi, dan atau hak kekayaan intelektual lainnya;',
        sec_5_content_2_10:'spamming, bermaksud untuk menjual apapun kepada para pengguna lainnya, atau mengandung unsur \t\tpersaingan bisnis dengan TADOTV;',
        sec_5_content_2_11:'mengandung virus komputer atau kode berbahaya lainnya yang dirancang untuk menyerang, menghancurkan, \t\tmengalihkan, mengambil alih, menghilangkan, membebani, atau secara lain mengganggu Layanan;',
        sec_5_content_3:'Kami berhak, kapan saja dan tanpa pemberitahuan terlebih dahulu, untuk menghapus atau menghalangi akses anda \tkepada konten apapun dengan alasan atau pun tanpa alasan. Beberapa alasan penghapusan atau penghalangan \takses yang mungkin kami lakukan dapat termasuk bertentangan dengan Syarat dan Ketentuan Penggunaan atau \tsecara lain dapat membahayakan Layanan atau para pengguna kami;',
        sec_5_content_4:'Kami berhak, kapan saja dan tanpa pemberitahuan terlebih dahulu, untuk mengubah dan/atau menghapus setiap \tkonten (termasuk video) yang dibuat untuk dan dipublikasikan di dalam Layanan, dengan alasan atau pun tanpa \talasan sama sekali;',
        sec_5_content_5:'Anda mengakui bahwa kami tidak berkewajiban untuk selalu memantau akses anda terhadap atau penggunaan \tLayanan yang mungkin melanggar Syarat dan Ketentuan Penggunaan ini, atau untuk meninjau atau melakukan \tperubahan konten apapun. Namun, kami memiliki hak untuk melakukan hal tersebut untuk tujuan menjalankan dan \tmengembangkan Layanan (termasuk namun tidak terbatas untuk pencegahan terjadinya kecurangan, penilaian \tresiko, penyelidikan, dan keperluan dukungan pelanggan), untuk memastikan kepatuhan anda terhadap Syarat dan \tKetentuan Penggunaan ini dan untuk memenuhi ketentuan hukum yang berlaku atau putusan atau persyaratan \tpengadilan, surat keputusan dari lembaga administratif atau lembaga pemerintahan. Kami juga dapat memblokir \tatau pun menanggapi konten yang menurut pendapat kami tidak pantas atau pun bertentangan dengan Syarat dan \tKetentuan Penggunaan ini. Sebagai tambahan, anda mengakui bahwa kami memiliki hak untuk memantau dan \tmemblokir konten yang menurut kami mengandung pelecehan dan intimidasi.',
        sec_5_content_6:'Jika anda menggunakan layanan atau fitur yang disediakan oleh pihak ketiga yang tersedia melalui Layanan kami, \tsyarat dan ketentuan dari tiap-tiap pihak akan mengatur hubungan diantara anda dan pihak ketiga tersebut. \tTADOTV tidak bertanggung jawab atas syarat dan ketentuan atau pun tindakan yang dilakukan berdasarkan syarat \tdan ketentuan dari pihak ketiga tersebut.',
        sec_5_content_7:'Anda tidak diperkenankan menggunakan Layanan ini untuk maksud :',
        sec_5_content_7_1:'mengumpulkan data dan informasi para pengguna lainnya, atau mengakses Layanan menggunakan sarana \t\totomatis (seperti, harvesting bots, robot, spiders, atau scrapers) tanpa izin dari kami sebelumnya;',
        sec_5_content_7_2:'melanggar tiap robot exlusion headers dari situs ini, jika ada,  atau melewati atau menggagalkan tindakan lain \t\tyang berfungsi untuk mencegah dan membatasi akses kepada Layanan;',
        sec_5_content_7_3:'membagikan, mengkompilasi ulang, mendekompilasi,  membongkar, membalikan sistem, atau membuat atau \t\tmendistribusikan bentuk lain dari atau bentuk turunan dari Layanan;',
        sec_5_content_7_4:'mengumpulkan data atau informasi pribadi dari pengguna lainnya atau dari dalam Layanan;',
        sec_5_content_7_5:'berpura-pura menjadi orang lain  selain diri anda sendiri atau meniru indentitas orang lain;',
        sec_5_content_7_6:'mengakses bagian atau fitur dari Layanan yang  tidak diperkenankan untuk anda.',
        sec_5_content_8:'Setiap perangkat lunak yang telah kami sediakan dapat secara otomatis anda unduh dan instal peningkatan, \tpembaruan, atau fitur-fitur baru lainnya. Anda dapat menyesuaikan pengaturan unduh otomatis ini melalui \tpengaturan di perangkat anda.',
        sec_5_content_9:'Anda tidak diperbolehkan untuk menyalin, merubah, mendistribusikan, menjual, atau menyewakan seluruh atau \tsebagian dari Layanan kami, atau mencoba untuk merekayasa kembali (reverse engineer) atau mengekstrak kode \tsumber dari perangkat lunak, kecuali jika hukum yang berlaku mengizinkan atau anda memiliki izin tertulis untuk \tmelakukannya.',
        sec_6_title:'LISENSI UNTUK MENGGUNAKAN LAYANAN',
        sec_6_content_1:'Kami memberikan kepada anda, lisensi pribadi, terbatas, dapat ditarik kembali, tidak khusus, dan tidak dapat \tdipindahtangankan untuk dapat mengakses dan menggunakan Layanan hanya sebagaimana diperkenankan \tberdasarkan Syarat dan Ketentuan Penggunaan ini. Anda tidak diperbolehkan menggunakan Layanan untuk tujuan \tilegal atau tujuan apapun yang tidak sesuai dengan Syarat dan Ketentuan Penggunaan ini. Anda diperbolehkan \tmenggunakan informasi yang tersedia melalui Layanan hanya untuk kepentingan pribadi, tidak komersil. Anda juga \tdiperbolehkan untuk mengunduh hal-hal yang tersedia di dalam Layanan untuk tujuan pribadi, tidak komersil \tsemata, dengan tetap menyertakan semua hak cipta dan hak intelektual lainnya yang terdapat atau terkandung di \tdalamnya. Kecuali disebutkan secara jelas di dalam Syarat dan Ketentuan Penggunaan ini, anda dilarang untuk \tmendistribusikan, mengubah, mengirim, menggunakan ulang, menautkan ulang atau menggunakan konten atau \tLayanan untuk tujuan apapun tanpa izin dari kami. Setiap pelanggaran oleh anda terhadap ketentuan lisensi dalam \tPasal 7 ini dapat mengakibatkan penghentian langsung hak anda untuk menggunakan Layanan, serta \tmengakibatkan adanya potensi anda untuk memberikan pertanggungjawaban berkenaan dengan pelanggaran hak \tcipta, tergantung keadaan yang ditimbulkan.',
        sec_6_content_2:'Kami berhak untuk mengubah atau menghentikan segala aspek dari Layanan kapan saja tanpa pemberitahuan \tsebelumnya kepada anda. Peningkatan atau pembaharuan dari Layanan mungkin akan tersedia dari waktu ke \twaktu. Kami melakukan hal ini untuk meningkatkan kualitas dari Layanan yang kami berikan kepada anda dan para \tpengguna. Perangkat lunak atau pun toko perangkat lunak yang menyediakan perangkat lunak tersedia untuk \tdiunduh mungkin juga mencakup fungsi untuk untuk secara otomatis memberikan pemberitahuan untuk \tpeningkatan atau pembaruan perangkat lunak. Kecuali perangkat anda, pengaturan atau perangkat lunaknya tidak \tmemperkenankan transmisi atau penggunaan peningkatan atau pembaruan, anda setuju bahwa kami atau toko \tperangkat lunak tersebut dapat memberikan pemberitahuan kepada anda mengenai adanya peningkatan atau \tpembaharuan tersebut dan secara otomatis menyediakan peningkatan atau pembaruan tersebut ke perangkat atau \tkomputer anda dari waktu ke waktu. Anda kemungkinan akan diminta untuk melakukan instal peningkatan atau \tpembaruan tertentu terhadap perangkat lunak agar dapat tetap mengakses atau menggunakan Layanan, atau \tbagian dari Layanan (termasuk peningkatan dan pembaruan yang bermaksud untuk memperbaiki masalah di dalam \tLayanan). Setiap peningkatan atau pun pembaruan yang disediakan untuk anda oleh kami berdasarkan Syarat dan \tKetentuan Penggunaan ini dianggap merupakan bagian yang tak terpisahkan dari Layanan.',
        sec_6_content_3:'Jika anda mengirimkan materi kepada Layanan, kecuali jika kami menentukan sebaliknya, anda telah memberikan \thak yang mutlak kepada kami (hanya untuk pertanyaan yang anda tanyakan), tidak khusus, bebas dari royalti dan \tsepenuhnya telah dibayar, dan dapat disub-lisensikan untuk mengakses, melihat, menggunakan, memproduksi \tkembali, mengubah, mengadopsi, menerbitkan, menerjemahkan, membuat pekerjaan turunan dari, \tmendistribusikan, membuat salinan, dan menunjukkan materi tersebut kepada dunia luas dalam bentuk atau pun \tteknologi apapun yang sudah ada atau yang dikembangkan di kemudian hari. Anda juga mengizinkan pengguna \tlain untuk melihat, membuat salinan, mengakses, menyimpan, atau memproduksi kembali materi-materi tersebut \tuntuk kepentingan pribadi pengguna tersebut. Anda memberikan hak kepada kami untuk menggunakan nama atau \tnama pengguna anda yang telah anda daftarkan sehubungan dengan materi tersebut. Anda menyatakan dan \tmenjamin bahwa anda memiliki atau dengan cara lain mengendalikan segala hak terhadap materi yang anda \tkirimkan; bahwa materi yang anda kirimkan benar dan akurat; dan penggunaan atas materi yang anda kirimkan \ttidak akan melanggar Syarat dan Ketentuan Penggunaan ini, hak-hak dari pihak ketiga, atau peraturan perundang-\tundangan yang berlaku. Anda membebaskan tanpa syarat semua hak moral yang berkaitan dengan materi yang \tanda kirimkan kepada Layanan berdasarkan peraturan perundang-undangan yang berlaku dari waktu ke waktu di \tbagian dunia manapun.',
        sec_7_title:'HAK ATAS KEKAYAAN INTELEKTUAL',
        sec_7_content:'Layanan ini merupakan kepemilikan dan dilindungi oleh undang-undang hak cipta, ketentuan perjanjian internasional, merek dagang, merek jasa, dan undang-undang dan perjanjian kekayaan intelektual lainnya. Layanan ini juga dilindungi sebagai karya kolektif atau kompilasi berdasarkan undang-undang hak cipta dan undang-undang dan perjanjian lainnya. Anda setuju untuk mematuhi semua undang-undang hak cipta dan undang-undang lainnya yang berlaku, termasuk surat pemberitahuan atau batasan hak cipta lainnya yang terdapat dalam Layanan. Anda mengakui bahwa Layanan berisi karya asli dan telah diciptakan, dibentuk, dipersiapkan, direvisi, dipilih, dan disusun oleh TADOTV adalah kekayaan intelektual TADOTV yang berharga. Anda setuju untuk melindungi hak kepemilikan TADOTV dan lainnya yang memiliki hak dalam Layanan selama dan setelah jangka waktu perjanjian ini dan untuk mematuhi semua permintaan tertulis yang wajar yang dibuat oleh TADOTV untuk melindungi hak kontraktual dan perundang-undangan dalam Layanan.\n' +
        'TADOTV berkomitmen untuk melindungi kekayaan intelektual orang lain, dan kami meminta hal yang sama dari setiap pengguna Layanan. TADOTV tidak menjamin atau menyatakan bahwa penggunaan material anda yang ditampilkan pada Layanan tidak akan melanggar hak pihak ketiga yang tidak dimiliki atau berafiliasi dengan TADOTV. Anda setuju untuk segera memberi tahu kami setelah mengetahui apabila terdapat klaim bahwa Layanan telah melanggar hak cipta, merek dagang, atau hak kontraktual atau undang-undang ditujukan kepada kontak di bawah. Dan jika TADOTV menyadari bahwa salah satu pengguna kami telah berkali-kali melanggar hak cipta, kami akan mengambil langkah yang wajar dalam wewenang kami untuk menghentikan akun pengguna.\n' +
        'Perlu diketahui bahwa semua video yang dibuat untuk dan dipublikasikan di TADOTV menjadi milik TADOTV sepenuhnya. TADOTV memiliki hak penuh untuk mengubah dan / atau menghapus video yang dibuat untuk dan dipublikasikan di TADOTV, dan menggunakannya untuk kepentingan bisnis TADOTV. TADOTV dilepaskan dari semua tanggung jawab sehubungan dengan konten video yang dibuat untuk dan dipublikasikan di TADOTV.',
        sec_8_title:'SANGGAHAN',
        sec_8_content:'ANDA SETUJU BAHWA LAYANAN DISEDIAKAN "APA ADANYA", "SEBAGAIMANA ADANYA", "DENGAN SEMUA DASAR YANG DAPAT DITANGGUHKAN" DAN SEJAUH DIIZINKAN OLEH UNDANG-UNDANG, TADOTV, ANGGOTA PENGURUS, PEMEGANG SAHAM,  PEJABAT, DIREKTUR, KARYAWAN, AFILIASI DAN AGEN KAMI MELEPASKAN TANPA JAMINAN APAPUN, BAIK TERSURAT MAUPUN TERSIRAT, TERMASUK NAMUN TIDAK TERBATAS PADA, JAMINAN TERSIRAT TENTANG KELAYAKAN UNTUK DIPERDAGANGKAN, KESESUAIAN UNTUK TUJUAN TERTENTU, JUDUL, DAN NON PELANGGARAN. SELAIN ITU, SAAT TADOTV BERUSAHA UNTUK MENYEDIAKAN PENGALAMAN TERBAIK BAGI PENGGUNA, KAMI TIDAK MENYATAKAN ATAU MENJAMIN BAHWA: (1) LAYANAN AKAN SELALU AMAN, TANPA GANGGUAN, ATAU TEPAT  WAKTU; (2) LAYANAN AKAN SELALU BERFUNGSI TANPA PENUNDAAN, GANGGUAN, ATAU KETIDAKSEMPURNAAN; (3) KONTEN APAPUN, KONTEN PENGGUNA, ATAU INFORMASI YANG ANDA DAPATKAN  DARI ATAU MELALUI LAYANAN AKAN TEPAT WAKTU ATAU AKURAT; (4) KERUGIAN PRIBADI APAPUN ATAU KERUSAKAN PROPERTI APAPUN, DALAM BENTUK ATAU SIFAT APAPUN YANG DISEBABKAN DARI AKSES ANDA TERHADAP LAYANAN ATAU PENGGUNAAN LAYANAN; (5) SETIAP AKSES ATAU PENGGUNAAN TIDAK SAH DARI LAYANAN KAMI DAN / ATAU SETIAP DAN SEGALA INFORMASI PRIBADI DAN/ATAU INFORMASI KEUANGAN YANG TERSIMPAN DI DALAMNYA; (6) SETIAP GANGGUAN ATAU PENGHENTIAN TRANSMISI MENUJU ATAU DARI LAYANAN KAMI; (7) SEGALA BUG, VIRUS, ATAU LAINNYA YANG DITRANSMISIKAN KEPADA ATAU MELALUI LAYANAN KAMI OLEH PIHAK KETIGA; DAN/ATAU (8) KESALAHAN APAPUN DALAM KONTEN APAPUN ATAU KERUGIAN ATAU KERUSAKAN APAPUN YANG DIAKIBATKAN DARI PENGGUNAAN KONTEN APAPUN YANG DITAUTKAN, DITRANSMISIKAN, ATAU SECARA LAIN TERSEDIA MELALUI LAYANAN INI.\n' +
        'TADOTV TIDAK BERTANGGUNG JAWAB DAN TIDAK DAPAT DIPERTANGGUNG JAWABKAN ATAS KONTEN APAPUN YANG ANDA, PENGGUNA LAIN, ATAU PIHAK KETIGA CIPTAKAN, UNGGAH, TAUTKAN,  BERI KOMENTAR, KIRIM, TERIMA ATAU SIMPAN DALAM ATAU MELALUI LAYANAN KAMI. ANDA SETUJU DAN MEMAHAMI BAHWA ANDA MUNGKIN SAJA TERHUBUNG DENGAN KONTEN YANG MENYERANG, MELAWAN HUKUM, MENYESATKAN, ATAU TIDAK SESUAI, YANG MANA TADOTV TIDAK DAPAT DIMINTA PERTANGGUNGJAWABANNYA UNTUK ITU.',
        sec_9_title:'PEMBATASAN TANGGUNG JAWAB',
        sec_9_content:'TADOTV, ANGGOTA PENGURUS, PEMEGANG SAHAM, PEJABAT, DIREKTUR, KARYAWAN, AFILIASI DAN AGEN TIDAK AKAN BERTANGGUNG JAWAB ATAS KERUSAKAN TIDAK LANGSUNG, INSIDENTAL, KHUSUS, KONSEKUENSIAL, HUKUMAN, ATAU SEMACAMNYA, ATAU HILANGNYA LABA ATAU PENDAPATAN, BAIK TERJADI LANGSUNG ATAU TIDAK LANGSUNG, ATAU HILANGNYA DATA, PEMAKAIAN, REPUTASI, ATAU KERUGIAN TIDAK BERWUJUD LAINNYA, YANG DIAKIBATKAN DARI: (1) KERUSAKAN, KESALAHAN, ATAU KETIDAKAKURATAN KONTEN; (2) AKSES ATAU PENGGUNAAN ATAU KETIDAKMAMPUAN ANDA UNTUK MENGAKSES ATAU MENGGUNAKAN LAYANAN; (3) KERUGIAN PRIBADI ATAU KERUSAKAN PROPERTI YANG DIAKIBATKAN OLEH AKSES ANDA KE DAN PENGGUNAAN LAYANAN KAMI; (4) TINDAKAN ATAU KONTEN PENGGUNA LAIN ATAU PIHAK KETIGA PADA ATAU MELALUI LAYANAN; (5) SETIAP AKSES ATAU PENGGUNAAN YANG TIDAK SAH DARI SERVER KAMI YANG TELAH DIAMANKAN DAN/ATAU SETIAP DAN SEGALA INFORMASI PRIBADI DAN/ATAU INFORMASI KEUANGAN YANG TERSIMPAN DI DALAMNYA; (6) SETIAP GANGGUAN ATAU PENGHENTIAN TRANSMISI MENUJU ATAU DARI LAYANAN KAMI; (7) SEGALA BUG, VIRUS, ATAU LAINNYA YANG DITRANSMISIKAN KEPADA ATAU MELALUI LAYANAN KAMI OLEH PIHAK KETIGA; DAN/ATAU (8) KESALAHAN APAPUN DALAM KONTEN APAPUN ATAU KERUGIAN ATAU KERUSAKAN APAPUN YANG DIAKIBATKAN DARI PENGGUNAAN KONTEN APAPUN YANG DITAUTKAN, DITRANSMISIKAN, ATAU SECARA LAIN TERSEDIA MELALUI LAYANAN INI, MESKIPUN TADOTV TELAH DIBERITAHUKAN MENGENAI ADANYA KEMUNGKINAN KERUSAKAN TERSEBUT. PEMBATASAN TANGGUNG JAWAB AKAN BERLAKU SEJAUH DIIZINKAN OLEH HUKUM DALAM YURISDIKSI YANG BERLAKU.\n' +
        'ANDA SECARA KHUSUS MENGAKUI BAHWA TADOTV TIDAK AKAN BERTANGGUNG JAWAB ATAS KONTEN ATAU PERILAKU FITNAH, MENYERANG, ATAU PERBUATAN MELAWAN HUKUM PIHAK KETIGA DAN BAHWA RESIKO BAHAYA ATAU KERUSAKAN DARI HAL TERSEBUT SEPENUHNYA DIBEBANKAN KEPADA ANDA.',
        sec_10_title:'GANTI RUGI',
        sec_10_content:'Anda setuju untuk membela, mengganti kerugian dan membebaskan TADOTV, anggota pengurus, pemegang saham, pejabat, direktur, karyawan, afiliasi, dan agen kami dari dan terhadap setiap dan segala tuntutan, kerusakan (aktual dan/atau konsekuensial), kewajiban, tindakan, proses, tuntutan, kerugian, kewajiban, biaya atau hutang, dan pengeluaran (termasuk namun tidak terbatas pada biaya hukum) yang timbul dari: (1) penggunaan dan akses anda terhadap Layanan; (2) pelanggaran anda terhadap Syarat dan Ketentuan Penggunaan ini; (3) pelanggaran anda terhadap hak pihak ketiga apapun, termasuk namun tidak terbatas pada hak cipta, hak kepemilikan, atau hak privat; (4) pelanggaran anda terhadap setiap hukum, aturan, peraturan, kode, undang-undang, ordonansi atau perintah dari lembaga pemerintah dan kuasi-pemerintah manapun, termasuk namun tidak terbatas pada semua lembaga peraturan, administratif dan legislatif; (5) segala kekeliruan pernyataan yang anda buat; atau (6) setiap klaim bahwa Konten anda menyebabkan kerugian pada pihak ketiga. Kewajiban pembelaan dan ganti rugi ini akan tetap berlaku terlepas dari  keberlakuan Syarat dan Ketentuan Penggunaan ini dan penggunaan Layanan oleh anda. TADOTV berhak untuk menanggung pembelaan dan kontrol eksklusif atas hal apapun bergantung pada penggantian kerugian oleh anda, dan anda tidak akan dalam segala hal menyelesaikan klaim apapun tanpa persetujuan tertulis terlebih dahulu dari TADOTV.',
        sec_11_title:'UMUM',
        sec_11_content_1:'Hukum dan Yurisdiksi yang Berlaku. Anda setuju bahwa: (i) Layanan akan diberikan dari Indonesia; (Ii) hukum \tIndonesia berlaku untuk Syarat dan Ketentuan Penggunaan ini, termasuk setiap permasalahan kontraktual atau \tnon-kontraktual atau perselisihan yang timbul dari atau sehubungan dengan Syarat dan Ketentuan Penggunaan ini, \takses dan penggunaan Layanan oleh anda, dan hubungan antara kami dengan anda; dan (iii) setiap konflik yang \ttimbul dari atau sehubungan dengan Layanan atau konflik antara kami dengan anda sehubungan dengan Layanan \takan diajukan dan akhirnya diselesaikan oleh Badan Arbitrase Nasional Indonesia. Dengan tidak mengesampingkan \thal tersebut di atas, anda setuju bahwa kami dapat meminta putusan provisi, putusan sela atau protective relief di \thadapan pengadilan yang kompeten di wilayah yurisdiksi manapun.',
        sec_11_content_2:'Biaya Data. Dengan menggunakan Layanan, termasuk fitur tertentu seperti mengundang teman, di ponsel Anda \t(dan/atau perangkat lain), anda dapat dikenai biaya oleh penyedia layanan Internet atau seluler anda, jadi periksa \tterlebih dahulu dengan mereka apabila anda tidak yakin, karena anda akan bertanggung jawab atas biaya tersebut.',
        sec_11_content_3:'Keseluruhan Perjanjian dan Pelepasan. Syarat dan Ketentuan Penggunaan ini, beserta Kebijakan Kerahasiaan \tmerupakan keseluruhan perjanjian antara anda dengan kami terkait Layanan. Jika, dengan alasan apapun, ketentuan \tapapun dalam Syarat dan Ketentuan Penggunaan ini dinyatakan melawan hukum, tidak sah, batal demi hukum atau \tsecara lain tidak dapat diterapkan oleh pengadilan yang kompeten di wilayah hukum manapun, maka sepanjang \tketentuan tersebut melawan hukum, tidak sah, batal demi hukum atau tidak dapat diterapkan, ketentuan tersebut \takan dipisahkan dan dihapuskan dari  Syarat dan Ketentuan Penggunaan ini dan ketentuan lain dari Syarat dan \tKetentuan Penggunaan ini akan bertahan, tetap berlaku penuh dan efektif dan terus mengikat dan dapat \tdilaksanakan. Kegagalan atau penundaan kami dalam menjalankan setiap hak, kewenangan atau hak istimewa \tberdasarkan Syarat dan Ketentuan Penggunaan ini tidak akan dianggap sebagai pelepasan dari hak tersebut atau \tpenerimaan atas setiap perubahan dari Syarat dan Ketentuan Penggunaan ini dan juga pelaksanaan tunggal atau \tsebagian oleh salah satu pihak dari setiap hak, kewenangan atau hak istimewa tidak akan menghalangi \tpelaksanaan lebih lanjut dari hak tersebut atau pelaksanaan hak, kewenangan atau hak istimewa lainnya.',
        sec_11_content_4:'Tidak Ada Hak Pihak Ketiga. Tidak ada ketentuan dalam Syarat dan Ketentuan Penggunaan ini yang akan \tmemberikan atau dimaksudkan untuk memberikan hak apapun kepada pihak ketiga lainnya.',
        sec_11_content_5:'Pembatasan Pengalihan. Anda tidak akan mengalihkan hak atau kewajiban anda berdasarkan Syarat dan \tKetentuan Penggunaan ini kepada orang lain tanpa persetujuan tertulis dari kami. Semua hak dan kewajiban kami \tberdasarkan \tSyarat dan Ketentuan Penggunaan dapat dialihkan secara bebas oleh kami sehubungan dengan \tmerger, akuisisi, \tkonsolidasi, reorganisasi, penjualan aset, oleh tindakan hukum atau yang lain.',
        sec_11_content_6:'Menghubungkan dan Framing. Anda tidak dapat  melakukan frame terhadap Layanan. Anda dapat \tmenghubungkan \tdengan Layanan, dengan ketentuan bahwa anda mengetahui dan menyetujui bahwa anda tidak \takan menghubungkan Layanan ke situs web manapun yang berisi topik, nama, materi, atau informasi yang tidak s\tepatutnya, mencemarkan, memfitnah, melanggar, cabul, tidak senonoh, atau melanggar hukum, atau yang \tmelanggar hak kekayaan intelektual, kepemilikan, privasi, atau publisitas apapun. Pelanggaran terhadap ketentuan \tini dapat, atas kebijaksanaan kami sendiri, mengakibatkan penghentian penggunaan dan akses  anda terhadap \tLayanan secara efektif dan segera.',
        contact_us:'HUBUNGI KAMI',
        contact_us_content:'TADOTV menyambut baik komentar, pertanyaan, pemikiran, atau saran bagi TADOTV. Silahkan kirim ke '
    },
    privacy_policy:{
        title:'KEBIJAKAN KERAHASIAAN',
        content_1:'TADOTV berhak untuk mengambil, mengolah, dan menyimpan informasi pribadi anda, yang tidak terbatas pada \tdata dan informasi yang telah disampaikan pada saat pembuatan akun.',
        content_2:'Anda dan TADOTV dapat bersama-sama ataupun sendiri-sendiri terlibat dalam kegiatan pemasaran dan iklan untuk \tmempromosikan Layanan kami. Anda dengan ini memberikan TADOTV persetujuan untuk menggunakan informasi \tpribadi anda untuk tujuan pemasaran dan iklan tersebut.',
        content_3:'Kerahasiaan para pengguna merupakan prioritas yang utama bagi TADOTV, dan TADOTV berjanji untuk \tmemberikan penjagan dan perhatian penuh terkait dengan tindakan keamanannya guna keberlangsungan \tkeamanan informasi dari para penggunanya. TADOTV tidak akan menjual atau membagikan informasi pribadi \tapapun mengenai anda dengan pihak ketiga.',
        content_4:'TADOTV dapat mengakses, menjaga, atau mengungkapkan setiap informasi anda apabila diwajibkan oleh hukum, \tatau apabila kami yakin dengan iktikad baik bahwa hal tersebut secara wajar diperlukan (i) untuk menanggapi \ttuntutan yang diajukan kepada kami atau untuk memenuhi proses hukum (misalnya untuk memenuhi panggilan \tpengadilan atau surat perintah pengadilan), termasuk yang dikeluarkan oleh pengadilan yang memiliki yurisdiksi \tatas kami atau anda, (ii) untuk menegakkan atau menjalankan perjanjian kami dengan para pengguna, seperti \tSyarat dan Ketentuan Penggunaan ini, (iii) untuk pencegahan kecurangan, penilaian resiko, penyelidikan, dukungan \tbagi pelanggan, penyediaan Layanan atau untuk dukungan teknik,  atau (iv) untuk melindungi hak, properti, atau \tkeamanan TADOTV, para pengguna Layanan, atau anggota masyarakat pada umumnya.',
        content_5:'Dalam hal terjadi penutupan akun, TADOTV akan menghapus data anda sesegera mungkin, namun dalam kasus \ttertentu, jenis-jenis data tertentu, termasuk data log dan backup, dapat memakan waktu sampai dengan 90 hari \tuntuk dapat terhapus sepenuhnya..  Mohon menjadi perhatian anda bahwa dalam hal anda menonaktifkan sendiri \takun anda, walaupun anda telah menghapus keseluruhan informasi dari profil akun anda atau menonaktifkan akun \tanda, salinan dari konten tersebut kemungkinan masih dapat terlihat dan/atau dapat diakses di internet sejauh \tinformasi tersebut telah terlebih dahulu anda bagikan dengan para pengguna lainnya, atau sejauh informasi \ttersebut telah dibagi dengan, masuk ke dalam index atau telah di cache dalam mesin pencari. Begitu juga dengan \tapabila anda telah menautkan akun anda dengan situs pihak ketiga (misalnya, media sosial) yang dapat mengakses \tinformasi pribadi anda, mereka kemungkinan masih menyimpan informasi tersebut. Kami tidak dapat  \tmengendalikan hal tersebut dan kami tidak bertanggung jawab atas hal tersebut.',
    },
}
export default id;
