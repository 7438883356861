import React from 'react';
import CONFIG from '../constants/config'
import rewindButton2 from '../assets/images/tadoPlayer/rewind copy.png';
import rewindButton from '../assets/images/tadoPlayer/rewind-button.png';
import iphoneButton from '../assets/images/AppStore.png';
import googleButton from '../assets/images/tadoPlayer/google-play-badge.png';
import NextEpisodeBig from '../assets/images/tadoPlayer/Dekstop/Big Next Episode List.png'
import NextEpisodeDisBig from '../assets/images/tadoPlayer/Dekstop/Big Next Episode List Disabled.png'
import Tracking from '../utils/Tracking'
const EndPoster = ({showEndPoster, title,is_series = false,isLastEpisode = false,series="",genre="",showIphoneImage,showAndroidImage,checkPointClick,replayMovie,nextEpisode,langText,MovieId = 0,whiteLabel=false,isMobile = false}) => {
    const tracking = new Tracking();
    return (
        <div className="end-poster" id="end-poster" style={{
            display:showEndPoster?'block':'none'
        }}>
            <div className="overlay"></div>
            <div className="info">
                <div className="title">{title}</div>
                <div className="action">
                    <a href="#rewind" id="end-poster-checkpoint" className="prev-clip" onClick={() => checkPointClick()}>
                        <img alt="lastcheckpoint" src={rewindButton} /><br/>
                        <span>{langText.last_checkPoint}</span>
                    </a>
                    <a href="#replay" className="replay" onClick={()=>{replayMovie()}}>
                        <img alt="replay" src={rewindButton2}/><br/>
                        <span>{langText.replay_movie}</span>
                    </a>
                    <a href="#next" style={{display:is_series?'block':'none'}} className="next-episode" onClick={()=>{
                        if(!isLastEpisode){
                            nextEpisode()
                        }
                    }}>
                        <img alt="next" src={(isLastEpisode?NextEpisodeDisBig:NextEpisodeBig)}/><br/>
                        <span style={(isLastEpisode)?{color:'#b3b3b3'}:{}}>{langText.next_episode}</span>
                    </a>
                </div>
                {
                    (isMobile && !whiteLabel) || (!isMobile && !whiteLabel)?<div className="apps-link">
                        <p>{langText.enjoy_tado}</p>
                        <a href="#download"  style={{display:(showIphoneImage)?'inline-block':'none'}} onClick={()=>{
                            tracking.pxTrackDownloadVideoPlayer(title,series,genre);
                            window.open(
                                CONFIG.appstoreUrl,
                                '_blank'
                            );
                        }}><img alt="iphone" src={iphoneButton} /></a>
                        <a href="download" style={{display:(showAndroidImage)?'inline-block':'none'}} onClick={()=>{
                            tracking.pxTrackDownloadVideoPlayer(title,series,genre);
                            window.open(
                                CONFIG.playstoreUrl,
                                '_blank'
                            );
                        }}><img alt="android" src={googleButton} /></a>
                    </div>:""
                }

            </div>
        </div>
    )
}

export default EndPoster;


