/**
 * Created by rinaldiguarsa on 20/06/19.
 */

import React from 'react'
import MobileDetect from '../../../node_modules/mobile-detect/';

class BrandTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            md:new MobileDetect(window.navigator.userAgent)
        };
    }

    componentDidMount() {

    }
    render () {
        return (
            <div className="coming-soon">&nbsp;</div>
        )
    }
}

export default BrandTab;
