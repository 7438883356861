import React from 'react';
import InfoClip from './InfoClip'

const PausePoster = ({showPausePoster, genre,series_name,title,duration,synopsis}) => {
    return (
        <div className="pause-poster" id="pause-poster" style={{
            display:showPausePoster?'block':'none'
        }}>
            <InfoClip
                genre={genre}
                title={title}
                series_name={series_name}
                duration={duration}
                synopsis={synopsis}
            />
        </div>
    )
}

export default PausePoster;

