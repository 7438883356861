/**
 * Created by rinaldiguarsa on 20/06/19.
 */

import React from 'react';
import MobileDetect from '../../../node_modules/mobile-detect/';
import './Product.less';
import 'fullpage.js/dist/jquery.fullpage.min.css';
import iphoneButton from '../../assets/images/AppStore.png';
import googleButton from '../../assets/images/GooglePlay.png';
import Tracking from '../../utils/Tracking';
import CONFIG from '../../constants/config';

const $ = require('jquery');
$.fullpage = require('fullpage.js');

class ProductTab extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      md: new MobileDetect(window.navigator.userAgent),
    };
  }

  componentDidMount() {
    this.tracking = new Tracking();
    $(document).ready(function() {
      $('#fullpage').fullpage({
        navigation: true,
        navigationPosition: 'right',
      });
    });
  }

  componentWillUnmount() {
    $.fn.fullpage.destroy('all');
  }

  render() {

    return (
      <div id="fullpage" className="product-page">
        <div className="section product-1">
          <img className="logo-name" src={require('../../assets/landing-page/tado-studio.png')} />
          <h2>Where Storytelling Meets Technology</h2>
          <p>We provide you an innovation of cinematic storytelling through interactive technology</p>
          <a onClick={() => {
            this.props.clickMenu('contact');
          }}><button>Book A Demo</button></a>
        </div>
        <div className="section product-2">
          <img className="logo-name" src={require('../../assets/landing-page/tado-pictures.png')} />
          <h2>Transforming Ideas into Interactive Content</h2>
          <p>We express creative ideas through powerful narrative that suits client’s communication strategy to meet their vision.</p>
          <p>Our aim it to make wonderful stories to enhance client’s brand values</p>
          <a href="https://tadotv.com/watch/dad-ep1" target="_blank"><button>Watch Case Study</button></a>
        </div>
        <div className="section product-3">
          <img className="logo-name" src={require('../../assets/landing-page/tado-originals.png')} />
          <h2>We Tell the Story You Choose</h2>
          <p>We offer interactive videos with various themes and genres, creating a more engaged and emotionally-connected experience for our users</p>
          <a href="https://tadotv.com/watch/gK5Twfm" target="_blank"><button>Watch Case Study</button></a>
        </div>
        <div className="section product-4">
          <img className="logo-name" src={require('../../assets/landing-page/tado-tv.png')} />
          <h2>The First Interactive-Video Platform in South East Asia</h2>
          <p>We create interactive interactive video platform to make meaningful connections between you and the story</p>
          {
            (this.state.md.mobile() === null || (this.state.md.mobile() !== null && this.state.md.is('Iphone'))) && <a onClick={()=>{
              if(this.state.md.mobile() !== null) {
                this.tracking.pxTrackDownloadLandingPage();
              }
              window.open(
                CONFIG.appstoreUrl,
                '_blank'
              );
            }} href="#download" className="app-link"><img alt="App Store" src={iphoneButton} /></a>
          }
          {
            (this.state.md.mobile() === null || (this.state.md.mobile() !== null && !this.state.md.is('Iphone'))) && <a onClick={()=>{
              if(this.state.md.mobile() !== null) {
                this.tracking.pxTrackDownloadLandingPage();
              }
              window.open(
                CONFIG.playstoreUrl,
                '_blank'
              );
            }} href="#download" className="app-link"><img alt="Play Store" src={googleButton} /></a>
          }
        </div>
        <div className="section">
          <h2>Case Studies</h2>
          <div className="case-preview">
            <a href="https://tadotv.com/watch/montir-itvc" target="_blank">
              <img src={require(this.state.md.mobile() !== null ? '../../assets/landing-page/768-products---5-image-1.png': '../../assets/landing-page/1024-products---5-image-1.png')} />
            </a>
            <a href="https://tadotv.com/watch/dad-ep1" target="_blank">
              <img src={require(this.state.md.mobile() !== null ? '../../assets/landing-page/768-products---5-image-2.png': '../../assets/landing-page/1024-products---5-image-2.png')} />
            </a>
            <a href="https://tadotv.com/watch/gK5Twfm" target="_blank">
              <img src={require(this.state.md.mobile() !== null ? '../../assets/landing-page/768-products---5-image-3.png': '../../assets/landing-page/1024-products---5-image-3.png')} />
            </a>
            <a href="https://tadotv.com/watch/1k4SdSg" target="_blank">
              <img src={require(this.state.md.mobile() !== null ? '../../assets/landing-page/768-products---5-image-4.png': '../../assets/landing-page/1024-products---5-image-4.png')} />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default ProductTab;
