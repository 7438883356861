const defaultBannerState = {
    id:"",
    link: "",
    button: {
        text: "",
        text_color: "",
        color: ""
    },
    banner: {
        horizontal: null,
        horizontal_time: {
            start:0,
            end:0
        },
        vertical: null,
        vertical_time: {
            start:0,
            end:0
        },
        vertical_long: null,
        vertical_long_time: {
            start:0,
            end:0
        }
    }
}

const defaultStatePlayer = {
    inFrame:false,
    defaultLang:'id',
    bridgeDefaultLang:'id',
    slug: "",
    whiteLabel: false,
    md: null,
    bd: "",
    id: null,
    analytic:false,
    is_series:false,
    title: "",
    genre: "",
    synopsis: "",
    url: "",
    series_name: "",
    allow_fullscreen:true,
    is_potrait:true,
    shareable:true,
    bridge_allow_skip:false,
    thumbnail: {
        mobile: null,
        desktop: null
    },
    artwork: {
        small: "",
        large: ""
    },
    duration: {
        short: "",
        long: ""
    },
    view: "",
    watch_uid:"",
    moviePlot: [],
    episode_list:[],
    elm:{
        showInitialPoster:true,
        showVideoContainer:false,
        showPausePoster:false,
        showEndPoster:false,
        showVideoControl:false,
        showBridgeControl:false,
        showSettingContainer:false,
        showSettingContent:false,
        showShareControl:false,
        showShareButton:true,
        showFullscreenButton:false,
        showSettingButton:true,
        showQualityContent:false,
        showSubtitleContent:false,
        showIphoneImage:false,
        showAndroidImage:false,
        playPauseButton:null,
        volumeButton:null,
        splashButton:null,
        isFullscreen:false,
        isOpenVideoControl:false,
        isOpenSettingControl:false,
        fullScreenButton:null,
        showEpisodeList:false,
        showBanner:false,
        //for mobile
        volumeText:'Sound ON',
        showBannerVertical:false,
        //for desktop
        showVolumeBox:false,
        isPause:false,
        fsUpper:false,
        fsLower:true,
        showPlayerCLip:true,
        showPlayerBridge:false
    },
    playerInfo : {
        duration : 0,
        volume : 1,
        videoType : 'movie',
        lastIndexMovie : 0,
        currentIndexMovie : 0,
        nextIndexMovie : 0,
        hasEnded:false,
        hasStart:false,
        qualityLevelList:[],
        qualityLevel:'auto',
        subtitle:'off',
        subtitleList:[],
        qualityLevelLabel:"",
        subtitleLabel:"",
        currentTime:0,
        muted:false,
        bridgeState:'play',
        clipState:'play',
        closedBanner:false,
        isLastEpisode:true,
        nextEpisodeSlug:'',
        lockedOpt:false,
        userGesture:false,
        changeQualityState:false
    },
    bridgeObject:{
        bridgeSubtitle:"id",
        bridgeQuestion:"",
        bridgeOption:[]
    },
    bannerInfo:defaultBannerState,
    showBlank:false,
    isMobile:false,
    isIphone:false,
    lang:null,
    orientation:'portrait',
    loadingText:'',
    showRotateInfo:true,
    client_id:null,
    sendp:null,
    nofs:false,
};
export function generateDefaultStatePlayer(
    slug,whiteLabel,mobileDetect,browserDetect,analytic,playPauseButton,volumeButton,fullScreenButton,splashButton,
    qualityLevelLabel,subtitleLabel,isMobile,isIphone,lang,inFrame = false,soundLabel = null,loadingText = '',client_id=null,
    sendp = false,nofullscreen = false
){
    let tempState = defaultStatePlayer;
    tempState.slug = slug;
    tempState.inFrame = inFrame;
    tempState.whiteLabel = whiteLabel;
    tempState.md = mobileDetect;
    tempState.bd = browserDetect;
    tempState.analytic = analytic;
    tempState.isMobile = isMobile;
    tempState.isIphone = isIphone;
    tempState.lang = lang;
    tempState.elm.playPauseButton = playPauseButton;
    tempState.elm.volumeButton = volumeButton;
    tempState.elm.fullScreenButton = fullScreenButton;
    tempState.elm.splashButton = splashButton;
    tempState.playerInfo.qualityLevelLabel = qualityLevelLabel;
    tempState.playerInfo.subtitleLabel = subtitleLabel;
    if(soundLabel!=null){
        tempState.elm.volumeText = soundLabel;
    }
    if(loadingText!==''){
        tempState.loadingText = loadingText;
    }
    tempState.client_id = client_id;
    tempState.sendp = sendp;
    tempState.nofs = nofullscreen;
    return tempState
}

export {defaultBannerState}

export const configPlayer = {
    controls: false,
    autoplay: false,
    qualityLevels: {},
    preload: 'auto'
    ,html5:{
        hls:{
            overrideNative:true
        },
        nativeAudioTracks: false,
        nativeVideoTracks: false
    }
};

