/**
 * Created by kuyarawa on 24/09/18.
 */

import React, {Component} from 'react'
import _ from 'lodash'
import './Banner.less'
import BannerCloseIcon from '../assets/images/tadoPlayer/banner close.png'
import Tracking from '../utils/Tracking'

const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const BannerType = 'hr';

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile :this.props.isMobile,
            showBanner: this.props.showBanner,
            analyticStatus:this.props.analyticStatus,
            type:"movie",
            is_endplot:this.props.is_endplot,
            media:{
                id:"",
                link: "",
                button: {
                    text: "",
                    text_color: "",
                    color: ""
                },
                banner: {
                    horizontal: "",
                    horizontal_time: {
                        start:0,
                        end:0
                    },
                    vertical: "",
                    vertical_time: {
                        start:0,
                        end:0
                    },
                    vertical_long: "",
                    vertical_long_time: {
                        start:0,
                        end:0
                    }
                }
            },

        }
        this.clickBanner = this.clickBanner.bind(this);
        this.closeBanner = this.closeBanner.bind(this);
        this.tracking = new Tracking();
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...!_.isEqual(props.showBanner, state.showBanner) ? {
                showBanner: props.showBanner
            }: {},
            ...!_.isEqual(props.type, state.type) ? {
                type: props.type
            }: {},
            ...!_.isEqual(props.is_endplot, state.is_endplot) ? {
                is_endplot: props.is_endplot
            }: {},
            ...!_.isEqual(props.media, state.media) ? {
                media: props.media
            }: {},
            ...!_.isEqual(props.analyticStatus, state.analyticStatus) ? {
                analyticStatus: props.analyticStatus
            }: {},
            ...!_.isEqual(props.isMobile, state.isMobile) ? {
                isMobile: props.isMobile
            }: {}
        }
    }

    componentDidMount() {
        let tmpImgWidth = this.calcWidth(w);
        if(this.state.isMobile){
            tmpImgWidth = w-16;
        }
        this.imgWidth = tmpImgWidth;
        this.imgHeight = this.calcHeight(tmpImgWidth);
        this.tracking.setAnalyticStatus(this.state.analyticStatus);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextProps.showBanner, this.state.showBanner)  || !_.isEqual(nextProps.media, this.state.media)
    }

    calcWidth(docWidth){
        return parseFloat(docWidth*0.4).toFixed(2);
    }

    calcHeight(imgWidth){
        return parseFloat(parseFloat(imgWidth)/3.8).toFixed(2);
    }

    getSrc(type,is_endplot){
        if(is_endplot){
            return 3;
        }
        return type==='movie'?2:1;
    }

    clickBanner(){
        const src = this.getSrc(this.state.type,this.state.is_endplot);
        window.open(
            this.state.media.link,
            '_blank'
        );
        this.props.closeBanner('click',this.state.media.id,BannerType,src);
    }

    closeBanner(){
        const src = this.getSrc(this.state.type,this.state.is_endplot);
        this.props.closeBanner('close',this.state.media.id,BannerType,src);
    }

    render() {
        const imgSrc = this.state.media.banner.horizontal;
        return (
            <div id={this.state.isMobile?'banner-mobile':'banner'} style={{display:this.state.showBanner?'block':'none'}}>
                <a href="#tr" onClick={()=>this.closeBanner()} className="banner-close" style={{display:imgSrc!==null?'block':'none',zIndex:1002
                }} ><img src={BannerCloseIcon} alt="icon" /></a>
                <a href="#tr" onClick={()=>this.clickBanner()}>
                    <img src={imgSrc} alt="banner" style={{
                        display:imgSrc!==null?'block':'none'
                    }} className="banner-image"/>
                </a>
            </div>
        )
    }
}

export default Banner
