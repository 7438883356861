import React from 'react';
import tadologoWhite from '../assets/images/tadoPlayer/Dekstop/TADOlogo.png';
import bigPlayButton from '../assets/images/tadoPlayer/play-translucent.png';
import InfoClip from './InfoClip'

const InitPosterWeb = ({showInitialPoster, artwork,initialPlay,genre,series_name,title,duration,synopsis,thumbnail,load = false,whiteLabel = false}) => {
    if(thumbnail.desktop!==null && !load){
        return (
            <div id="poster-web-thumbnail" style={{
                display:(showInitialPoster)?'block':'none'
            }}>
                <div className="initial-play">
                    <a href="#play" onClick={() => initialPlay()} >
                        <img src={bigPlayButton} alt="play" />
                    </a>
                </div>

                <img className="poster-bg" alt="bg" src={thumbnail.desktop}/>
                {!whiteLabel?<img alt="Tado Logo" src={tadologoWhite} className="tado-logo-thumbnail"/>:''}
            </div>
        )
    }else if(!load){
        return (
            <div id="poster-web" className="poster" style={{
                display:(showInitialPoster)?'block':'none'
            }}>
                <img alt="Tado Logo" src={tadologoWhite} className="tado-logo"/>
                <div className="overlay"></div>
                <div className="img-poster">
                    <img alt="artwork" src={artwork} id="img-poster-web"/>
                </div>
                <div className="play-area">
                    <a href="#play" id="init-play" onClick={() => initialPlay()} >&nbsp;</a>
                </div>
                <InfoClip
                    genre={genre}
                    title={title}
                    series_name={series_name}
                    duration={duration}
                    synopsis={synopsis}
                />
            </div>
        )
    }else{
        return (null)
    }

}

export default InitPosterWeb;


