/**
 * Created by kuyarawa on 24/09/18.
 */

import React, {Component} from 'react'
import _ from 'lodash'
import lockedIcon from '../assets/images/tadoPlayer/lock(play).png';
import {createBannerUrl} from "../utils/utils";
import MobileDetect from '../../node_modules/mobile-detect/';

class BridgeControl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            series: "",
            genre: "",
            md:new MobileDetect(window.navigator.userAgent),
            bridgeQuestion: "",
            bridgeOption: [],
            showBridgeControl:false,
            bridgeLang:"ID",
            langText:this.props.langText,
            lockedOpt:this.props.lockedOpt,
            allowDbCLick:this.props.allowDbCLick,
            orientation:this.props.orientation,
            forceMobile:false
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...!_.isEqual(props.bridgeOption, state.bridgeOption) ? {
                bridgeOption: props.bridgeOption
            }: {},
            ...!_.isEqual(props.bridgeQuestion, state.bridgeQuestion) ? {
                bridgeQuestion: props.bridgeQuestion
            }: {},
            ...!_.isEqual(props.showBridgeControl, state.showBridgeControl) ? {
                showBridgeControl: props.showBridgeControl
            }: {},
            ...!_.isEqual(props.title, state.title) ? {
                title: props.title
            }: {},
            ...!_.isEqual(props.bridgeLang, state.bridgeLang) ? {
                bridgeLang: props.bridgeLang
            }: {},
            ...!_.isEqual(props.langText, state.langText) ? {
                langText: props.langText
            }: {},
            ...!_.isEqual(props.series, state.series) ? {
                series: props.series
            }: {},
            ...!_.isEqual(props.genre, state.genre) ? {
                genre: props.genre
            }: {},
            ...!_.isEqual(props.lockedOpt, state.lockedOpt) ? {
                lockedOpt: props.lockedOpt
            }: {},
            ...!_.isEqual(props.allowDbCLick, state.allowDbCLick) ? {
                allowDbCLick: props.allowDbCLick
            }: {},
            ...!_.isEqual(props.orientation, state.orientation) ? {
                orientation: props.orientation
            }: {},
            ...!_.isEqual(props.forceMobile, state.forceMobile) ? {
                forceMobile: props.forceMobile
            }: {}
        }
    }

    componentDidMount() {

    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextProps.bridgeOption, this.state.bridgeOption)
            || !_.isEqual(nextProps.bridgeQuestion, this.state.bridgeQuestion)
            || !_.isEqual(nextProps.showBridgeControl, this.state.showBridgeControl)
            || !_.isEqual(nextProps.bridgeLang, this.state.bridgeLang)
            || !_.isEqual(nextProps.orientation, this.state.orientation)
    }

    handlerBridgeOption(event,id,locked){
        if(!locked && !this.state.lockedOpt){
            const isClickedText = event.target.classList.contains('text-el');
            const elmOption = isClickedText?event.target.parentElement.parentElement:event.target;
            let isActive = elmOption.classList.contains('active');
            let listBridgeOtion = document.querySelectorAll('.bridge-option-item');
            let lengthListBridgeOption = listBridgeOtion.length;
            for (let i = 0; i < lengthListBridgeOption; i++) {
                listBridgeOtion[i].classList.remove('active');
            }
            elmOption.classList.add('active');
            if(!this.state.allowDbCLick){
                isActive = false;
            }
            this.props.playNextMovie(id,isActive);
        }else if(locked){
            let url = 'https://tadotv.com/';
            if(this.state.md.mobile()!==null){
                try{
                    this.props.trackLockedOption();
                }catch(error){
                }
                url = createBannerUrl(this.state.title);
            }
            window.open(url,
                '_blank'
            );
        }
    }

    render() {
        const optionLength = this.state.bridgeOption.length;
        let lang = 'id';
        try{
            lang = this.state.bridgeLang.toUpperCase();
        }catch (e) {
            lang = 'id';
        }
        return (
            <div className={`bridge-control ${this.state.showBridgeControl?'slide-up-fade-in':''}`} id="bridge-control" style={{
                display:(this.state.showBridgeControl)?'block':'none'
            }}>
                <div style={(((this.state.md.mobile()!==null && this.state.orientation==='landscape') || this.state.md.mobile()===null ) && !this.state.forceMobile)?{display:'flex',flexDirection:'column-reverse'}:{}}>
                    <div className="bridge-bar">
                        <div className="bar-progress">
                            <div id="bar-fill"></div>
                        </div>
                    </div>
                    <div className="bridge-question" style={((this.state.md.mobile()!==null && this.state.orientation==='landscape') || this.state.md.mobile()===null)?{marginBottom:'20px'}:{}}>
                        {this.state.bridgeQuestion[this.state.bridgeLang.toUpperCase()]}
                    </div>
                </div>
                <div className="bridge-option">
                    <div className="list-option">
                        <ul>
                            {_.map(this.state.bridgeOption, (opt, i) => {
                                if(opt.locked){
                                    return  <li key={i+opt.id} className="bridge-option-item locked"><a href="#bridge" onClick={(event)=>{this.handlerBridgeOption(event,opt.id,opt.locked)}} ><div><img alt="check" src={lockedIcon} /> {opt.title[this.state.bridgeLang.toUpperCase()]}</div></a><span
                                        className="locked-text" >{this.state.langText.watch_tado}</span></li>;
                                }else{
                                    let style = {};
                                    let classEl = 'text-el ';
                                    const textLength = opt.title[lang].length;
                                    if(this.state.md.mobile()!==null && this.state.orientation==='landscape'){
                                        style = {width:Math.floor(100/optionLength)+'%'};
                                        if(optionLength>3){
                                            if(textLength>11 && textLength<=21){
                                                classEl += 'el14';
                                            }else if(textLength>21){
                                                classEl += 'el12';
                                            }else{
                                                classEl += 'el18';
                                            }
                                        }else{
                                            if(opt.title[lang].length>11){
                                                classEl += 'el14';
                                            }else{
                                                classEl += 'el18';
                                            }
                                        }
                                    }else if(this.state.md.mobile()===null && !this.state.forceMobile){
                                        style = {width:Math.floor(100/optionLength)+'%'};
                                        if(optionLength>2){
                                            classEl += 'el16';
                                        }else{
                                            classEl += 'el18';
                                        }
                                    }
                                    return <li key={i+opt.id} className={`bridge-option-item opt-${opt.id}`} style={style} onClick={(event)=>{this.handlerBridgeOption(event,opt.id,opt.locked)}}><a href="#bridge" ><div className={classEl}>{opt.title[lang]}</div></a></li>;
                                }
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default BridgeControl
