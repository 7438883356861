export function injectFirefoxStyleInputRange(value) {
    let head = document.getElementsByTagName("HEAD")[0];
    let styleElm = document.getElementsByTagName("STYLE");
    let found = false;
    let foundElm = null;
    for (let index = styleElm.length - 1; index >= 0; index--) {
        if(styleElm[index].id==='ffInject'){
            foundElm = styleElm[index];
            found=true;
        }
    }
    if(found){
        foundElm.parentNode.removeChild(foundElm);
    }
    let newStyle = document.createElement('style');
    newStyle.id = 'ffInject';
    let styleCss = 'input[type=range]::-moz-range-track{ background-image: -webkit-gradient(\n' +
        '            linear,\n' +
        '            left top,\n' +
        '            right top,\n' +
        '            color-stop('+value+', #ffffff),\n' +
        '            color-stop('+value+', rgba(255,255,255,0.2))\n' +
        '    );}';
    newStyle.type = 'text/css';
    if (newStyle.styleSheet){
        // This is required for IE8 and below.
        newStyle.styleSheet.cssText = styleCss;
    } else {
        newStyle.appendChild(document.createTextNode(styleCss));
    }
    head.appendChild(newStyle);
}

export function getStyleChangeBGInputRange(value){
    return '-webkit-gradient(linear, left top, right top, '
        + 'color-stop(' + value + ', #ffffff), '
        + 'color-stop(' + value + ', rgba(255,255,255,0.2))'
        + ')';
}
