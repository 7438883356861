import CONFIG from '../constants/config'
import {axiosClientTadoPlayer} from "../utils/axiosClient";
import ReactPixel from'react-facebook-pixel'
import {logger,getTimeUtc} from '../utils/utils'
class Tracking{

    constructor(debug = false,analytic = true){
        this.debug = debug;
        this.pixel = ReactPixel;
        this.pixel.init(CONFIG.pixelId, {}, { debug: debug, autoConfig: false });
        this.token = localStorage.getItem('tp-token');
        this.analytic = analytic;
        this.inFrame = false;
        this.parentUrlFrame = "";

    }

    setAnalyticStatus(analytic = true){
        this.analytic = analytic;
    }

    setToken(token){
       this.token = token;
    }
    setInFrame(inFrame = false){
        this.inFrame = inFrame;
        if(this.inFrame){
            this.parentUrlFrame = document.referrer;
            if(document.referrer===undefined || document.referrer==null){
                const origin = window.location.ancestorOrigins;
                if(origin[0]!==undefined){
                     this.parentUrlFrame = origin[0];
                }
            }
            if(this.parentUrlFrame===undefined || this.parentUrlFrame==null){
               this.parentUrlFrame = "";
            }
        }
    }

    pxTrackPageView(){
        this.pixel.pageView();
    }

    pxTrackInitialPlay(title="",series="",genre=""){
        this.pixel.trackCustom( 'fpx_play_content', {
            fpx_content_type:(series!=='')?'series':'movie',
            fpx_series_title:series,
            fpx_content_title:title,
            fpx_genre:genre
        })
    }

    pxTrackDownloadLandingPage(){
        this.pixel.trackCustom( 'fpx_app_download', {
            fpx_directory:'landing_page'
        })
    }
    pxTrackDownloadVideoPlayer(title="",series="",genre="",source="banner"){
        this.pixel.trackCustom( 'fpx_app_download', {
            fpx_directory:'web_player',
            fpx_content_type:(series!=='')?'series':'movie',
            fpx_series_title:series,
            fpx_content_title:title,
            fpx_genre:genre,
            fpx_click_source:source
        })
    }
    pxTrackCompleteMovie(){
        this.pixel.trackCustom( 'fpx_content_complete', {})
    }

    platformInit(cid) {
        logger('Track : Platform Init',cid);
        return axiosClientTadoPlayer['post']('mobileweb/v1.6/platform/init',{cid:cid})
            .then(response => {
                localStorage.setItem('tp-token',response.data.data.token);
                return response.data.data;
            })
            .catch(error => {

            });
    }

    playVideo(id,watch_uid,end_plot = false,first_plot = false) {
        if(this.analytic){
            let data = {id:id,watch_uid:watch_uid,end_plot:end_plot,first_plot:first_plot};
            data = this.addParentUrlInFrame(data);
            logger('Track : Video Player',data);
            axiosClientTadoPlayer.defaults.headers.common['x-access-token'] = this.token;
            return axiosClientTadoPlayer['post']('mobileweb/v1.6/movie/play',[data])
                .catch(error => {

                });
        }else{
            logger('Track : Video Player Untracked');
        }
    }

    watchTime(id,watch_uid,duration){
        if(this.analytic){
            let data = {plot_id:id,watch_uid:watch_uid,duration:duration,event_time:getTimeUtc().toString()};
            // data = this.addParentUrlInFrame(data);
            logger('Track : Video Watch Time',data);
            axiosClientTadoPlayer.defaults.headers.common['x-access-token'] = this.token;
            return axiosClientTadoPlayer['post']('mobileweb/v1.6/movie/track/watch-time',[data])
                .catch(error => {

                });
        }else{
            logger('Track : Video Watch Time Untracked');
        }
    }

    bulkWatchTime(data,callback = null){
        if(this.analytic){
            logger('Track : Video Watch Time',JSON.stringify(data));
            axiosClientTadoPlayer.defaults.headers.common['x-access-token'] = this.token;
            return axiosClientTadoPlayer['post']('mobileweb/v1.6/movie/track/watch-time',data).then(response=>{
                // logger('Track : Video Watch Time log success',response.data.message);
                // logger('Track : Video Watch Time log param request',JSON.stringify(response.data.data.req));
                // logger('Track : Video Watch Time token',this.token);
            }).catch(error => {
                logger('error watch track',error);
                    if(callback!==null){
                        callback(data);
                    }
                });
        }else{
            logger('Track : Video Watch Time Untracked');
        }
    }

    bannerClick(id,type,src,plot_id){
        if(this.analytic) {
            let data = {id: id, src: src, type: type,plot_id:plot_id,event_time:getTimeUtc().toString()};
            data = this.addParentUrlInFrame(data);
            logger('Track : Banner Click', data);
            axiosClientTadoPlayer.defaults.headers.common['x-access-token'] = this.token;
            return axiosClientTadoPlayer['post']('mobileweb/v1.6/movie/track/banner/click', [data])
                .catch(error => {

                });
        }else{
            logger('Track : Banner Click Untracked');
        }
    }

    bannerImpression(id,type,src,plot_id){
        if(this.analytic) {
            let data = {id: id, src: src, type: type,plot_id:plot_id,event_time:getTimeUtc().toString()};
            data = this.addParentUrlInFrame(data);
            logger('Track : Banner Impression', data);
            axiosClientTadoPlayer.defaults.headers.common['x-access-token'] = this.token;
            return axiosClientTadoPlayer['post']('mobileweb/v1.6/movie/track/banner/impression', [data])
                .catch(error => {

                });
        }else{
            logger('Track : Banner Click Untracked');
        }
    }

    bannerClose(id,type,src,plot_id){
        if(this.analytic) {
            let data = {id: id, src: src, type: type,plot_id:plot_id,event_time:getTimeUtc().toString()};
            data = this.addParentUrlInFrame(data);
            logger('Track : Banner Close', data);
            axiosClientTadoPlayer.defaults.headers.common['x-access-token'] = this.token;
            return axiosClientTadoPlayer['post']('mobileweb/v1.6/movie/track/banner/close', [data])
                .catch(error => {

                });
        }else{
            logger('Track : Banner Close Untracked');
        }
    }

    endPoint(id){
        let data = {id:id};
        logger('Track : Banner Click',data);
        axiosClientTadoPlayer.defaults.headers.common['x-access-token'] = this.token;
        return axiosClientTadoPlayer['post']('mobileweb/v1.6/movie/track/banner',data)
            .catch(error => {

            });
    }

    trackEngine(engine){
        const data = {data:engine};
        return axiosClientTadoPlayer['post']('mobileweb/v1.6/platform/log',data)
            .catch(error => {

            });
    }
    addParentUrlInFrame(data){
        if(this.inFrame && this.parentUrlFrame!==""){
             return {...data,referrer:this.parentUrlFrame};
        }
        return data;
    }
}
export default Tracking;
