/**
 * Created by kuyarawa on 24/09/18.
 */

import React, {Component} from 'react'
import _ from 'lodash'
import './Banner.less'
import BannerCloseIcon from '../assets/images/tadoPlayer/close-cross@2x.png'
import MobileDetect from '../../node_modules/mobile-detect/';
import Tracking from '../utils/Tracking'
import {defaultBannerState} from '../utils/const'
const md = new MobileDetect(window.navigator.userAgent);
const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
const scale = h/w;
const zIndex = 1103;
const minusHeight = 200;
const minusWidth = 32;
class BannerVertical extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile : md.mobile()!==null,
            showBanner: this.props.showBanner,
            analyticStatus: this.props.analyticStatus,
            type:"movie",
            is_endplot:this.props.is_endplot,
            media:defaultBannerState,
            orientation:this.props.orientation

        }
        this.clickBanner = this.clickBanner.bind(this);
        this.closeBanner = this.closeBanner.bind(this);
        this.tracking = new Tracking();
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...!_.isEqual(props.showBanner, state.showBanner) ? {
                showBanner: props.showBanner
            }: {},
            ...!_.isEqual(props.type, state.type) ? {
                type: props.type
            }: {},
            ...!_.isEqual(props.is_endplot, state.is_endplot) ? {
                is_endplot: props.is_endplot
            }: {},
            ...!_.isEqual(props.media, state.media) ? {
                media: props.media
            }: {},
            ...!_.isEqual(props.analyticStatus, state.analyticStatus) ? {
                analyticStatus: props.analyticStatus
            }: {},
            ...!_.isEqual(props.orientation, state.orientation) ? {
                orientation: props.orientation
            }: {}
        }
    }

    componentDidMount() {
        this.imgWidth = w-minusWidth;
        this.imgHeight = h-minusHeight;
        this.tracking.setAnalyticStatus(this.state.analyticStatus);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextProps.showBanner, this.state.showBanner) || !_.isEqual(nextProps.orientation, this.state.orientation) || !_.isEqual(nextProps.media, this.state.media)
    }


    getSrc(type,is_endplot){
        if(is_endplot){
            return 3;
        }
        return type==='movie'?2:1;
    }

    clickBanner(type){
        const src = this.getSrc(this.state.type,this.state.is_endplot);
        window.open(
            this.state.media.link,
            '_blank'
        );
        this.props.closeBanner('click',this.state.media.id,type,src);
    }

    closeBanner(type){
        const src = this.getSrc(this.state.type,this.state.is_endplot);
        this.props.closeBanner('close',this.state.media.id,type,src);
    }

    render() {
        let imgSrc = this.state.media.banner.vertical;
        let type = 'vr';
        let bannerContainerWidth = this.imgWidth;
        if(this.state.orientation==='landscape'){
            this.imgWidth = '183px';
            this.imgHeight = '256px';
            bannerContainerWidth = '100%';
        }else{
            this.imgWidth = w-minusWidth;
            this.imgHeight = h-minusHeight;
            bannerContainerWidth = this.imgWidth;
            if(scale>=2){
                imgSrc = this.state.media.banner.vertical_long;
                type = 'vl';
            }
        }
        return (
            <div id="banner-vertical" style={{display:this.state.showBanner?'block':'none',zIndex:zIndex}}>
                <div className="banner-bg" style={{zIndex:zIndex,backgroundImage: `url(${imgSrc})`}}>&nbsp;</div>
                <div style={{zIndex:zIndex+1,width:bannerContainerWidth}} className="banner-container">
                    <div className="banner-close">
                        <a href="#tr" onClick={()=>{
                            this.closeBanner(type);
                        }}>
                            <img src={BannerCloseIcon} alt="banner-close"/>
                        </a>
                    </div>
                    <a href="#tr" onClick={()=>{
                        this.clickBanner(type);
                    }}>
                        <img className="banner-image" src={imgSrc} style={{width:this.imgWidth,height:this.imgHeight}} alt="banner"/>
                    </a>
                    <button className="banner-button" onClick={()=>{
                        this.clickBanner(type);
                    }} style={{width:this.imgWidth,background:this.state.media.button.color,color:this.state.media.button.text_color}}>{this.state.media.button.text}</button>
                </div>
            </div>
        )
    }
}

export default BannerVertical
