import en from '../lang/en'
import id from '../lang/id'
class Lang {
    constructor(lang = 'id'){
        this.lang = lang.toLowerCase();
        this.text = {
            id:id,
            en:en,
        }
    }
    translate(lang = 'id'){
        try{
            this.lang = lang.toLowerCase();
        }catch (e) {
            this.lang = 'id';
        }
        return this.text[this.lang];
    }
}
export default Lang;
